import * as React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import {
  Button,
  Relation,
  SpinnerDark,
  SubTitle as Title,
  Icons,
  SelectableCheckbox,
  AlertRetryable,
  useDialog,
  DialogProvider,
} from '@ampeersenergy/ampeers-ui-components';
import { useTheme } from 'styled-components/macro';
import { DateTime } from 'luxon';

import {
  ReadContractsBatchFullQuery,
  UpdateContractsBatchedValidationSuccess,
  UpdateContractsContractData,
  useReadContractsBatchFullQuery,
} from '../../../graphql-types';
import {
  yupBankAccount,
  yupBankAccountNullable,
} from '../../../helpers/validators/bankAccount';
import { GraphqlFormField } from '../../graphql-form/render';
import {
  removeTypename,
  typesafeTrimFilterUndefined,
} from '../../../helpers/utils';
import { useGraphqlForm } from '../../graphql-form/hooks/useGraphqlForm';
import { useTaskManagerWFM } from '../../task-manager/TaskManagerProviderWFM';
import type { FailedRow, PrepareImportResult } from '../import-flow';
import { yupUTCDate } from '../../../helpers/yupUTCDate';
import { yupAddress } from '../../../helpers/validators';

import {
  formatExistingValue,
  getObjectPath,
  removeEqualValues,
  translateLabel,
} from './prepareContractsHelper';
import {
  GraphQlFormStyled,
  StickyHeader,
  ContractBox,
  ValueGrid,
  ValueCell,
  HeaderCell,
  Subtitle,
  ValueLabel,
  ContractSelection,
  WarningIconWrapper,
  WarningIcon,
  ResetButton,
} from './styles';

export type ExistingContracts =
  ReadContractsBatchFullQuery['readContractsBatch'];

type ExtendedUpdateContractsBatchedValidationSuccess =
  UpdateContractsBatchedValidationSuccess & {
    selected?: boolean;
  };

type FormValueTypes = {
  contracts: ExtendedUpdateContractsBatchedValidationSuccess[];
};

const validationSchema = (existingContracts: ExistingContracts) => ({
  contracts: yup.array().of(
    yup.object().shape({
      contract: yup.object().shape({
        status: yup
          .string()
          .test(
            'valid-status',
            (value: string | undefined, context: yup.TestContext) => {
              if (!value) return true;

              const contractId = (context as any).from[1]?.value?.contractId;
              const existingContract = existingContracts?.find(
                (existing) => existing.id === contractId,
              );
              if (!existingContract?.status) return false;

              if (value === existingContract?.status) return true;

              if (
                ['LS_INTRANSFER', 'FS_ACTIVE'].includes(
                  existingContract.status ?? '',
                ) &&
                value === 'LS_ACTIVE'
              ) {
                return true;
              }
              if (
                existingContract.status === 'FS_INTRANSFER' &&
                ['FS_ACTIVE', 'LS_ACTIVE'].includes(value)
              ) {
                return true;
              }

              return context.createError({
                message: `Der Status kann nur von 'In Transfer' zu 'In Belieferung' geändert werden bzw. von 'In Belieferung (Vollversorgung)' zu 'In Belieferung (Mieterstrom)'`,
              });
            },
          ),
        startDate: yupUTCDate.test(
          'can-change',
          (value: Date | undefined, context: yup.TestContext) => {
            if (!value) return true;

            const contractId = (context as any).from[1]?.value?.contractId;
            const existingContract = existingContracts?.find(
              (existing) => existing.id === contractId,
            );
            if (!existingContract?.status) return false;

            const startDate = DateTime.fromJSDate(value);
            const existingStartDate = DateTime.fromISO(
              existingContract.startDate,
            );

            if (startDate.toISODate() === existingStartDate.toISODate())
              return true;

            if (existingContract.status === 'LS_INTRANSFER') return true;

            return context.createError({
              message: `Das Startdatum kann nur bei Verträgen im Status 'In Transfer' geändert werden.`,
            });
          },
        ),
        contractMeter: yup
          .object()
          .shape({
            addressShipping: yupAddress(),
          })
          .nullable()
          .default({}),
        customer: yup
          .object()
          .shape({
            address: yupAddress().nullable().default({}),
            addressBilling: yupAddress(false, { email: yup.string().email() })
              .nullable()
              .default({}),
            addressShipping: yup
              .object()
              .shape({
                country: yup.string().nullable(),
              })
              .nullable()
              .default({}),
            bankAccount: yup
              .object()
              .when('hasSepa', {
                is: true,
                then: yupBankAccount,
                otherwise: yupBankAccountNullable,
              })
              .nullable()
              .default({}),
          })
          .nullable()
          .default({}),
      }),
    }),
  ),
});

interface PrepareContractsUpdateProps {
  result: PrepareImportResult<UpdateContractsBatchedValidationSuccess>;
  resetImport: () => void;
  onClose: () => void;
}

export function PrepareContractsUpdate({
  result,
  resetImport,
  onClose,
}: PrepareContractsUpdateProps) {
  const { addJobToTaskManager } = useTaskManagerWFM();
  const [mutationError, setMutationError] = React.useState<Error | null>(null);

  const contractsToUpdate: ExtendedUpdateContractsBatchedValidationSuccess[] =
    result.valid.map((valid) => ({
      ...typesafeTrimFilterUndefined(removeTypename(valid), {
        filterNull: true,
      }),
      selected: true,
    }));

  const contractLabels = contractsToUpdate.map((c: any) => c.contractId);

  const { data: existingContractsResult, loading } =
    useReadContractsBatchFullQuery({
      variables: {
        ids: contractLabels,
      },
      skip: contractLabels.length === 0,
    });

  const existingContracts = React.useMemo(
    () => existingContractsResult?.readContractsBatch ?? [],
    [existingContractsResult?.readContractsBatch],
  );

  const handleSuccess = (data: any) => {
    if (
      data?.updateContractsBatched?.__typename === 'ContractsBatchedSuccess'
    ) {
      addJobToTaskManager(
        data.updateContractsBatched.jobId,
        data.updateContractsBatched.jobName,
      );
      onClose();
    } else {
      setMutationError(new Error(data));
    }
  };

  const formValues: FormValueTypes = React.useMemo(() => {
    const contracts = contractsToUpdate.map((contractInput) => {
      const matchingContract = existingContracts.find(
        (existing) => existing.id === contractInput.contractId,
      );
      if (matchingContract) {
        return contractInput;
      }
      return {
        ...contractInput,
        // We only send values that have been changed to the backend
        // so we need to initially set the partnerIds to null so that
        // they are perceived as changed and sent to the backend
        contract: {
          ...contractInput.contract,
          customer: {
            ...contractInput.contract?.customer,
            addressBilling: {
              ...contractInput.contract?.customer?.addressBilling,
              partnerId: null,
              additionalPartnerId: null,
            },
          },
        },
        selected: false,
      };
    });

    return {
      contracts,
    };
  }, [contractsToUpdate, existingContracts]);

  const validation = React.useMemo(
    () => validationSchema(existingContracts),
    [existingContracts],
  );

  if (loading) {
    return (
      <div css="display: flex; justify-content: center; align-items: center; height: 70vh;">
        <SpinnerDark size={55} />
      </div>
    );
  }

  const noValidInput = formValues.contracts.length === 0;

  return (
    <>
      <div css="display: flex; justify-content: space-between; padding: 24px 0 0;">
        <div>
          <Title css="margin: 6px 0;">Übersicht der Änderungen</Title>
          <Subtitle>Werte ohne Änderungen werden ignoriert</Subtitle>
        </div>
        <div>
          <Button secondary onClick={resetImport}>
            Neuer Import
          </Button>
        </div>
      </div>

      {mutationError ? <AlertRetryable error={mutationError} /> : null}
      {!noValidInput ? (
        <StickyHeader>
          <ValueGrid>
            <span />
            <HeaderCell>Aktuell</HeaderCell>
            <HeaderCell css="text-align: left;">Änderung</HeaderCell>
          </ValueGrid>
        </StickyHeader>
      ) : (
        <div css="height: 24px;" />
      )}
      <GraphQlFormStyled
        mutation="updateContractsBatched"
        readDocumentFields={['jobId', 'jobName']}
        isLoading={loading}
        labels={{ submit: 'Änderungen ausführen' }}
        values={formValues}
        onSuccess={handleSuccess}
        onBeforeSubmit={async (values: any) => {
          return {
            ...values,
            contracts: values.contracts
              .filter((value: any) => value.selected)
              .map(({ selected, ...rest }: any) => {
                const existingContract = existingContracts.find(
                  (c) => c.id === rest.contractId,
                );

                return {
                  ...rest,
                  contract: typesafeTrimFilterUndefined(
                    removeEqualValues(rest.contract, existingContract, '', [
                      'customer.addressBilling.partnerId',
                      'customer.addressBilling.additionalPartnerId',
                    ]),
                  ),
                };
              }),
          };
        }}
        startInEdit
        submitAllValues
        hideCancel
        disableEdit={noValidInput}
        validation={validation}
      >
        <DialogProvider>
          <PrepareContractsUpdateForm existingContracts={existingContracts} />
          <FailedContracts failed={result.failed} />
        </DialogProvider>
      </GraphQlFormStyled>
    </>
  );
}

interface PrepareContractsUpdateFormProps {
  existingContracts?: ExistingContracts;
}

function PrepareContractsUpdateForm({
  existingContracts,
}: PrepareContractsUpdateFormProps) {
  const { values, initialValues, setFieldValue } =
    useFormikContext<FormValueTypes>();
  const { getFieldProps } = useGraphqlForm();

  const { openConfirmation } = useDialog();

  const resetItemToInitial = async (
    field: string,
    value: any,
    isExisting?: boolean,
  ) => {
    const content = isExisting ? (
      <>
        Bist Du sicher, dass Du diesen Eintrag auf den initialen Wert des
        Imports zurücksetzen möchtest?
      </>
    ) : (
      <>
        Bist Du sicher, dass Du diesen Eintrag auf den bestehenden Wert des
        Vertrags zurücksetzen möchtest?
      </>
    );
    await openConfirmation({
      confirmButtonLabel: 'Zurücksetzen',
      dangerButton: true,
      content,
      title: `Eintrag zurücksetzen`,
      maxWidth: 'sm',
      handleRequest: async () => setFieldValue(field, value ?? '', true),
    });
  };

  return (
    <>
      {values.contracts.map(
        ({ contract, contractId, contractLabel, selected }, index) => {
          const matchingContract = existingContracts?.find(
            (c) => String(c.id) === String(contractId),
          );
          if (!matchingContract) {
            return (
              <ContractError
                key={contractId}
                contractLabel={contractLabel}
                message="Kein Vertrag gefunden"
              />
            );
          }

          const paths = getObjectPath(contract);
          const initialContract = initialValues.contracts.find(
            (initial) => initial.contractId === contractId,
          );
          const handleSelection = () => {
            setFieldValue(`contracts.[${index}].selected`, !selected);
          };

          return (
            <ContractBox key={contractId}>
              <ContractSelection>
                <SelectableCheckbox
                  selected={!!selected}
                  css="margin-top: -1px;"
                  onClick={handleSelection}
                />
                <div
                  aria-label="Vertrag auswählen"
                  css="cursor: pointer;"
                  onClick={handleSelection}
                  role="button"
                  tabIndex={0}
                  onKeyDown={handleSelection}
                >
                  <Relation
                    icon={Icons.Contract}
                    title={contractLabel}
                    iconSize={24}
                  />
                </div>
              </ContractSelection>
              {paths.map((path) => {
                const initialValue = get(initialContract?.contract, path);

                const value = get(contract, path);
                const existingValue = get(matchingContract, path);

                const name = `contracts.[${index}].contract.${path}`;
                const fieldProps = getFieldProps(name);

                const resetValue =
                  value === existingValue ? initialValue : existingValue;

                if (
                  value !== undefined &&
                  (initialValue !== existingValue ||
                    shouldUpdateBanking(
                      path,
                      initialContract?.contract,
                      matchingContract,
                    ))
                ) {
                  return (
                    <ValueGrid key={`${contractId}-${path}`}>
                      <ValueLabel as="label" htmlFor={name}>
                        {translateLabel(path, fieldProps?.label)}
                      </ValueLabel>
                      <ValueCell>
                        {formatExistingValue(path, existingValue, fieldProps)}
                      </ValueCell>
                      <div css="display: grid; grid-template-columns: auto 20px; align-items: center; gap: 4px;">
                        <GraphqlFormField
                          name={name}
                          label={null}
                          disabled={!selected}
                          hint={
                            value === existingValue
                              ? 'Identisch mit aktuellen Wert'
                              : undefined
                          }
                        />
                        <ResetButton
                          type="button"
                          aria-label="Wert zurücksetzen"
                          onClick={() => {
                            resetItemToInitial(
                              name,
                              resetValue,
                              value === existingValue,
                            );
                          }}
                        >
                          <Icons.Refresh size={12} />
                        </ResetButton>
                      </div>
                    </ValueGrid>
                  );
                }
                return null;
              })}
            </ContractBox>
          );
        },
      )}
    </>
  );
}

function shouldUpdateBanking(
  path: string,
  contract?: UpdateContractsContractData,
  existing?: ExistingContracts[0],
) {
  if (!path.startsWith('customer.bankAccount') || !contract || !existing)
    return false;
  return (
    (contract.customer?.hasSepa && !existing.customer?.hasSepa) ||
    (!contract.customer?.hasSepa && existing.customer?.hasSepa)
  );
}

interface FailedContractsProps {
  failed: FailedRow[];
}

function ContractError({
  contractLabel,
  message,
}: {
  contractLabel: string;
  message?: string;
}) {
  const theme = useTheme();
  return (
    <ContractBox>
      <ContractSelection>
        <WarningIconWrapper>
          <WarningIcon size={29} color={theme.palette.error.color} />
        </WarningIconWrapper>
        <Relation icon={Icons.Contract} title={contractLabel} iconSize={24} />
        {message ? <div css="margin-left: 3rem;">{message}</div> : null}
      </ContractSelection>
    </ContractBox>
  );
}

function FailedContracts({ failed }: FailedContractsProps) {
  return (
    <>
      {failed.map((value) => {
        return (
          <ContractError
            key={value.values[0]}
            contractLabel={value.values[0]}
            message={value.error}
          />
        );
      })}
    </>
  );
}
