import React from 'react';
import { Switch } from '@ampeersenergy/ampeers-ui-components';
import styled, { useTheme } from 'styled-components';

import { SettingsTitle } from '../style';
import {
  LOCAL_STORAGE_IDENTIFIER,
  getOptionalCookiesAccepted,
} from '../../../helpers/storageUtils';
import { loadMSClarityScript } from '../../../helpers/analyticsHelpers';

const SubTitle = styled.p`
  color: ${({ theme }) => theme.palette.textMuted};
`;

export default function CookieSettings() {
  const theme = useTheme();
  const optionalCookiesAccepted = getOptionalCookiesAccepted();
  const [optionalCookies, setOptionalCookies] = React.useState(
    optionalCookiesAccepted,
  );

  const onSetOptionalCookies = React.useCallback(() => {
    const isCookieSet = getOptionalCookiesAccepted();

    const newCookieValue = !isCookieSet;

    setOptionalCookies(newCookieValue);
    loadMSClarityScript(newCookieValue);
    localStorage.setItem(
      `${LOCAL_STORAGE_IDENTIFIER}.cookies.optional`,
      `${newCookieValue}`,
    );
  }, [setOptionalCookies]);

  return (
    <div>
      <SettingsTitle> Cookies</SettingsTitle>
      <SubTitle>
        Wir verwenden Cookies und ähnliche Technologien, um die Stabilität und
        Performance unserer Produkte zu verbessern.
      </SubTitle>
      <div>
        <Switch
          checked
          disabled
          activeColor={theme.palette.textSubtle}
          passiveColor={theme.palette.textSubtle}
          labelColor={theme.palette.textSubtle}
        >
          Notwendige Cookies
        </Switch>
        <Switch
          checked={optionalCookies}
          activeColor={theme.primaryColor}
          passiveColor={theme.palette.text}
          labelColor={theme.primaryColor}
          onChange={onSetOptionalCookies}
        >
          Optionale Cookies (z.B. Tracking)
        </Switch>
      </div>
    </div>
  );
}
