import { useApolloClient } from '@apollo/client';
import { Result, err, ok } from 'neverthrow';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ErrorMsg } from '../../../../components';
import { useTaskManager } from '../../../../components/task-manager/TaskManagerProvider';
import {
  InvoiceCycle,
  InvoiceDraft,
  InvoiceDraftTupleInput,
  ReadInvoiceDraftsQueryDocument,
  RejectInvoiceDraftDocument,
  Status,
  useAcceptInvoiceDraftMutation,
  useGenerateContractInvoiceDocumentPreviewMutation,
  useReadInvoiceDraftsQueryQuery,
  useRetryInvoiceDocumentCreationMutation,
} from '../../../../graphql-types';
import { buildDocumentUrl } from '../../../../helpers/buildDocumentUrl';

import { InvoiceDraftsTable } from './invoiceDraftsTable';

const sorter = (a: { id: string }, b: { id: string }) =>
  a.id.localeCompare(b.id);

export function InvoiceDraftsContainer({
  accountingName,
  accountingType,
  workflowName,
  uuid,
  paymentPeriodStart,
  paymentPeriodEnd,
  invoiceCycle,
}: {
  accountingName: string;
  accountingType: string;
  workflowName?: string;
  uuid: string;
  paymentPeriodStart?: string;
  paymentPeriodEnd?: string;
  invoiceCycle: InvoiceCycle;
}) {
  const client = useApolloClient();

  const { addJob, watchJob } = useTaskManager();
  const [activeJob, setActiveJob] = useState<string | undefined>();
  const [invoiceProcessing, setInvoiceProcessing] = useState(false);

  const {
    error: queryError,
    data: queryData,
    loading: queryLoading,
    refetch,
  } = useReadInvoiceDraftsQueryQuery({
    variables: {
      filterByOPCName: accountingName,
      showDocuments: true,
    },
  });

  useEffect(() => {
    const keepWatch = () => {
      if (!activeJob) return;

      const result = watchJob(activeJob);
      if (result && [Status.Completed, Status.Failed].includes(result)) {
        setInvoiceProcessing(false);
        setActiveJob(undefined);

        refetch({
          filterByOPCName: accountingName,
        });
      }
    };
    let timer: NodeJS.Timer | undefined;
    if (activeJob) {
      timer = setInterval(keepWatch, 2500);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [watchJob, activeJob, refetch, accountingName]);

  const [generateInvoicePreviewMutation] =
    useGenerateContractInvoiceDocumentPreviewMutation();

  const generateInvoicePreview = useCallback(
    async (contractLabel: string): Promise<Result<string, string>> => {
      try {
        const invoiceId = queryData?.drafts?.find(
          (draft) => draft.contractId === contractLabel,
        )?.invoiceId;

        const { data } = await generateInvoicePreviewMutation({
          variables: {
            contractLabel,
            opcName: accountingName,
            workflowUuid: uuid,
            invoiceCycle,
            invoiceId,
          },
        });
        const result = data?.generateContractInvoiceDocumentPreview;
        if (result?.__typename === 'ContractDocumentPreview') {
          const documentUrl =
            result.fileURL.split('/')[0] === 'documents'
              ? buildDocumentUrl(result.fileURL, false)
              : result.fileURL;
          return ok(documentUrl);
        }
        if (result?.__typename === 'UnknownError') {
          return err(result.message);
        }
        console.error(result);
        return err('Die Vorschau konnte nicht geladen werden.');
      } catch (error) {
        console.error(error);
        return err('Die Vorschau konnte nicht geladen werden.');
      }
    },
    [
      accountingName,
      generateInvoicePreviewMutation,
      invoiceCycle,
      uuid,
      queryData?.drafts,
    ],
  );

  const [acceptInvoiceDraftMutation] = useAcceptInvoiceDraftMutation();

  const [retryInvoiceDocumentCreationMutation] =
    useRetryInvoiceDocumentCreationMutation();

  const acceptInvoiceDrafts = async (
    invoiceDraftsTuple: InvoiceDraftTupleInput[],
  ) => {
    try {
      setInvoiceProcessing(true);
      const { data } = await acceptInvoiceDraftMutation({
        variables: {
          accountingType,
          accountingName,
          invoiceDraftsTuple,
          uuid,
          invoiceCycle,
        },
        refetchQueries: [
          {
            query: ReadInvoiceDraftsQueryDocument,
            variables: {
              filterByOPCName: accountingName,
            },
          },
        ],
      });

      if (
        data?.acceptInvoiceDraft?.__typename === 'AcceptInvoiceDraftResponse'
      ) {
        const result = data?.acceptInvoiceDraft;
        addJob(result.jobId, result.jobName);
        setActiveJob(result.jobId);
      } else {
        setInvoiceProcessing(false);
      }
    } catch (error) {
      setInvoiceProcessing(false);
      throw error;
    }
  };

  const declineInvoiceDrafts = async (
    invoiceDraftsTuple: InvoiceDraftTupleInput[],
  ) => {
    await client.mutate({
      mutation: RejectInvoiceDraftDocument,
      variables: {
        invoiceDraftsTuple,
        validityStart: paymentPeriodStart,
        validityEnd: paymentPeriodEnd,
        uuid,
      },
      refetchQueries: [
        {
          query: ReadInvoiceDraftsQueryDocument,
          variables: {
            filterByOPCName: accountingName,
          },
        },
      ],
      optimisticResponse: {
        __typename: 'Mutation',
        rejectInvoiceDraft: null,
      },
      update: (proxy: any) => {
        proxy.readQuery({
          query: ReadInvoiceDraftsQueryDocument,
          variables: {
            filterByOPCName: accountingName,
          },
        });

        let nextDrafts;
        proxy.writeQuery({
          query: ReadInvoiceDraftsQueryDocument,
          variables: {
            filterByOPCName: accountingName,
          },
          data: {
            drafts: nextDrafts,
          },
        });
      },
    });
  };

  const invoiceAction = async (
    action: 'accept' | 'reject',
    invoiceDraftsTuple: InvoiceDraftTupleInput[],
  ): Promise<Result<void, Error>> => {
    try {
      if (action === 'accept') {
        await acceptInvoiceDrafts(invoiceDraftsTuple);
      } else if (action === 'reject') {
        await declineInvoiceDrafts(invoiceDraftsTuple);
      }
    } catch (error) {
      return err(error as Error);
    }

    refetch({
      filterByOPCName: accountingName,
    });

    return ok(undefined);
  };

  const [confirmed, unconfirmed] = useMemo(() => {
    const _confirmed: InvoiceDraft[] = [];
    const _unconfirmed: InvoiceDraft[] = [];

    if (queryData?.drafts) {
      queryData.drafts.forEach((draft: InvoiceDraft) => {
        if (draft.confirmed) {
          _confirmed.push(draft);
        } else {
          _unconfirmed.push(draft);
        }
      });

      return [_confirmed.sort(sorter), _unconfirmed.sort(sorter)];
    }
    return [[], []];
  }, [queryData]);

  const retryDocumentCreation = React.useCallback(async () => {
    setInvoiceProcessing(true);
    try {
      const { data } = await retryInvoiceDocumentCreationMutation({
        variables: {
          accountingType,
          accountingName,
          invoiceDraftsTuple: confirmed
            .filter((c) => !c.invoiceDocumentUrl)
            .map((c) => ({
              contractLabel: c.contractId,
              invoiceDraftId: c.id,
            })),
          uuid,
          invoiceCycle,
        },
      });

      if (
        data?.retryInvoiceDocumentCreation?.__typename ===
        'AcceptInvoiceDraftResponse'
      ) {
        const result = data?.retryInvoiceDocumentCreation;
        addJob(result.jobId, result.jobName);
        setActiveJob(result.jobId);
      } else {
        setInvoiceProcessing(false);
      }
    } catch (error) {
      setInvoiceProcessing(false);
      throw error;
    }
  }, [
    accountingName,
    accountingType,
    addJob,
    confirmed,
    invoiceCycle,
    retryInvoiceDocumentCreationMutation,
    uuid,
  ]);

  return (
    <>
      {queryError && <ErrorMsg error={queryError} />}
      <InvoiceDraftsTable
        confirmed={confirmed}
        unconfirmed={unconfirmed}
        invoiceAction={invoiceAction}
        isLoading={queryLoading}
        previewInvoice={generateInvoicePreview}
        invoiceProcessing={invoiceProcessing}
        accountingName={accountingName}
        workflowName={workflowName}
        retryDocumentCreation={retryDocumentCreation}
      />
    </>
  );
}
