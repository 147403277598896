// @ts-nocheck

import React, { useState } from 'react';
import styled from 'styled-components';
import { Relation, Modal } from '@ampeersenergy/ampeers-ui-components';

import { ReactPropType } from '../../types/util.types';
import './crm-board-card.scss';
import { CrmBoardCardType } from '../crm-board/crm-board.types';
import { usePlantDetailQuery } from '../../../../graphql-types';
import { StyledLink } from '../../../../components/graphql-form/style';
import { ProjectIcon, CreateContractIcon } from '../../../../components/icons';
import { Entry } from '../../../../components';
import { isTrueStringBool } from '../../../../helpers/utils';
import { EMPTY_STR, formatDate } from '../../../../helpers/formatStrings';

import ConfirmationDialog from './confirmationDialog';

export type CrmBoardCardPropType = {
  dragging: boolean;
  card: CrmBoardCardType;
  onEdit?: (card: CrmBoardCardType) => void;
  onDelete?: (card: CrmBoardCardType) => void;
  isInLastColumn?: boolean;
  goToCreateContract: (card: CrmBoardCardType) => void;
};

const EntryStyling = styled.div`
  max-height: 20px;
`;
const RelationStyling = styled.div`
  width: 190px;
  > div > div:first-child ~ div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const iconBaseProps = `
  width: 20px;
  height: 20px;
  background-color: rgba(0,0,0,0);
  border: none;
  position: absolute;
  cursor: pointer;
  &:hover {
    svg {
      fill: ${(props) => props.theme.primaryColor};
    }
  }
`;

const IconWrapperTakeOver = styled.div`
  ${iconBaseProps}
  bottom: 8px;
  left: auto;
  right: 8px;
  top: auto;
  height: 25px;
`;

const IconWrapperDelete = styled.div`
  ${iconBaseProps}
  font-size: 20px;
  bottom: auto;
  left: 5px;
  right: auto;
  top: 5px;
  margin-top: -7.5px;
  transform: rotate(45deg);
`;

function CrmBoardCard({
  card,
  onEdit,
  onDelete,
  isInLastColumn,
  goToCreateContract,
}: ReactPropType<CrmBoardCardPropType>) {
  const {
    created,
    expectedConsumption,
    isperson,
    prefix,
    firstName,
    lastName,
    companyname,
    mail,
    phone,
    plantid,
    status,
    shippingaddressstreet,
    shippingaddressstreetnr,
    shippingaddresszipcode,
    shippingaddresscity,
  } = card;
  const [isConfirmationDialogVisible, setConfirmationDialogVisible] =
    useState(false);

  const ispersonBool = isTrueStringBool(isperson);

  const { data, loading } = usePlantDetailQuery({
    variables: { plantId: plantid },
    skip: !plantid,
  });

  const adjustedPrefix = prefix && prefix !== 'keine Angabe' ? prefix : '';

  const plant = data?.readPlant;
  const plantName = data?.readPlant?.name;
  const pathToPlant = `/project/${plant?.project?.id}/plant/${plant?.id}`;
  return (
    <>
      <Modal
        isOpen={isConfirmationDialogVisible}
        contentLabel="confirmation-dialog"
        onRequestClose={() => setConfirmationDialogVisible(false)}
        title="Löschen"
      >
        <ConfirmationDialog
          onDone={() => setConfirmationDialogVisible(false)}
          onSuccess={() => {
            if (onDelete !== undefined) onDelete(card);
            setConfirmationDialogVisible(false);
          }}
        />
      </Modal>
      <ae-ui-card class="card">
        <ae-ui-card-header slot="header">
          {ispersonBool ? (
            <ae-ui-card-title>
              {adjustedPrefix} {firstName} {lastName}
            </ae-ui-card-title>
          ) : (
            <ae-ui-card-title>{companyname}</ae-ui-card-title>
          )}
          <ae-ui-card-subtitle>
            {shippingaddressstreet} {shippingaddressstreetnr}
          </ae-ui-card-subtitle>
          <ae-ui-card-subtitle>
            {shippingaddresszipcode} {shippingaddresscity}
          </ae-ui-card-subtitle>
          <ae-ui-card-subtitle>
            <EntryStyling>
              <Entry title={null} isLoading={loading}>
                {plantName ? (
                  <StyledLink to={pathToPlant}>
                    <RelationStyling>
                      <Relation icon={ProjectIcon} title={plantName} />
                    </RelationStyling>
                  </StyledLink>
                ) : (
                  ''
                )}
              </Entry>
            </EntryStyling>
          </ae-ui-card-subtitle>
        </ae-ui-card-header>

        <ae-ui-card-content>
          <span className="label">
            avisierter JV:{' '}
            {(expectedConsumption !== null &&
            expectedConsumption !== '' &&
            expectedConsumption !== undefined
              ? expectedConsumption
              : EMPTY_STR
            ).toLocaleString()}{' '}
            kWh
          </span>

          <br />
          <span className="label">
            erstellt am {formatDate(created.toISOString())}
          </span>
        </ae-ui-card-content>

        <ae-ui-card-actions class="actions" slot="header">
          {mail !== undefined && (
            <ae-ui-icon-button href={`mailto:${mail}`}>
              <ae-ui-icon-mail />
            </ae-ui-icon-button>
          )}
          {phone !== undefined && (
            <ae-ui-icon-button href={`tel:${phone}`}>
              <ae-ui-icon-phone />
            </ae-ui-icon-button>
          )}
        </ae-ui-card-actions>

        <ae-ui-icon-button
          class="edit"
          onClick={() => onEdit !== undefined && onEdit(card)}
        >
          <ae-ui-icon-pencil />
        </ae-ui-icon-button>

        {isInLastColumn && (
          <IconWrapperTakeOver onClick={() => goToCreateContract(card)}>
            <CreateContractIcon size={25} />
          </IconWrapperTakeOver>
        )}
        <IconWrapperDelete onClick={() => setConfirmationDialogVisible(true)}>
          &#8853;
        </IconWrapperDelete>

        {/* <ae-ui-card-initials slot="avatar">
          {ispersonBool ? firstName[0] : companyname[0]}
          {ispersonBool ? lastName[0] : companyname[1]}
        </ae-ui-card-initials> */}
        <ae-ui-card-status-decoration status={status} />
      </ae-ui-card>
    </>
  );
}

export default CrmBoardCard;
