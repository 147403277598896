import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { Button } from '@ampeersenergy/ampeers-ui-components';
import { useApolloClient } from '@apollo/client';
import { merge } from 'lodash';

import { FormikSubmit } from '../input';
import ErrorMsg from '../errorMsg';
import ErrorBoundary from '../errorBoundary';
import { ValidationError } from '../../graphql-types';

import { ActionBtns } from './style';
import { GraphqlFormContext } from './hooks/useGraphqlForm';
import { buildNestedYupSchema } from './validation';
import { useIntrospection } from './hooks/useIntrospection';
import { submit, cleanValues } from './submit';
import { __DEV__ } from './isDev';
import { IbanExistsErrorClass } from './types';

interface Labels {
  submit?: string;
}

export interface GraphQlFormProps<ValuesType> {
  mutation: string;
  startInEdit?: boolean;
  values?: ValuesType;
  defaultValues?: ValuesType;
  children: React.ReactNode;
  onBeforeSubmit?: (
    values: ValuesType,
    formikHelpers: FormikHelpers<any>,
  ) => Promise<ValuesType>;
  onSuccess?: (result: any, { values }: { values: any }) => void;
  onAbort?: () => void;
  onError?: (error: any) => void;
  variables?: any;
  isLoading?: boolean;
  readDocument?: any;
  readDocumentFields?: string[];
  labels?: Labels;
  refetchQueries?: any[];
  disableEdit?: boolean;
  formVariables?: any;
  validation?: any;
  validationDependencies?: { [key: string]: [string, string] }; // Avoids Cyclic dependency with multiple validations using same formVariable
  formatBeforeSubmit?: (values: any) => any;
  hideCancel?: boolean;
  hideSubmit?: boolean;
  submitAllValues?: boolean;
  className?: string;
}

function GraphQlForm<ValuesType extends unknown>({
  mutation,
  startInEdit,
  values,
  defaultValues: userDefaultValues,
  children,
  onBeforeSubmit,
  onSuccess: onSuccessCallback,
  onAbort: onAbortCallback,
  onError: onErrorCallback,
  variables,
  isLoading: isLoadingUser,
  readDocument,
  readDocumentFields,
  labels,
  refetchQueries,
  disableEdit,
  formVariables,
  validation,
  validationDependencies,
  formatBeforeSubmit,
  hideCancel,
  hideSubmit,
  submitAllValues = false,
  className,
}: GraphQlFormProps<ValuesType>) {
  const isMounted = useRef<boolean | undefined>();

  const client = useApolloClient();
  const [isEditing, setIsEditing] = useState(startInEdit ?? false);
  const [mutationError, setMutationError] = useState<unknown>(null);
  /**
   * formik provides `isSubmitting` on his own but is not fast enough
   * in updating it which can result in double submits
   */
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  });

  const {
    isLoading: isIntrospectionLoading,
    validationSchema,
    fieldProps,
    initialValues,
    schema,
    getFieldProps,
    defaultValues,
  } = useIntrospection(mutation, values);

  const isLoading = !!(isIntrospectionLoading || isLoadingUser);

  const formikInitialValues = useMemo(
    () =>
      values
        ? cleanValues(values)
        : merge({}, initialValues, defaultValues, userDefaultValues),
    [defaultValues, initialValues, userDefaultValues, values],
  );

  const formContextValue = useMemo(
    () => ({
      getFieldProps,
      isEditing,
      isLoading,
      formVariables,
      registerField: () => {},
      unregisterField: () => {},
    }),
    [formVariables, getFieldProps, isEditing, isLoading],
  );

  const renderChildren = useCallback(() => {
    if (children) {
      return (
        <GraphqlFormContext.Provider value={formContextValue}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </GraphqlFormContext.Provider>
      );
    }
  }, [children, formContextValue]);

  const onAbort = useCallback(
    (resetForm) => {
      setIsEditing(false);
      resetForm(initialValues);

      if (onAbortCallback) {
        onAbortCallback();
      }
      setMutationError(null);
    },
    [initialValues, onAbortCallback],
  );

  const onSubmit = useCallback(
    async (
      newValues: any,
      { setSubmitting: setFormikSubmitting, setStatus, setTouched }: any,
    ) => {
      if (!fieldProps || !initialValues) {
        return;
      }

      if (isSubmitting) {
        return;
      }

      setSubmitting(true);
      setFormikSubmitting(true);

      const formattedValues = formatBeforeSubmit
        ? formatBeforeSubmit(newValues)
        : newValues;

      try {
        const result = await submit(
          {
            newValues: formattedValues,
            values,
            initialValues,
            fieldProps,
            refetchQueries,
            mutation,
            schema,
            readDocument,
            readDocumentFields,
            client,
            variables,
          },
          submitAllValues,
        );

        if (result && result.data) {
          if (onSuccessCallback) {
            onSuccessCallback(result.data, { values: formattedValues });
          }
        }
        if (isMounted.current) {
          setIsEditing(false);
          setMutationError(null);
        }
      } catch (error) {
        if (__DEV__) {
          console.error(error);
        }
        if (onErrorCallback) {
          onErrorCallback(error);
        }
        if (
          Array.isArray(error) &&
          error[0]?.__typename === 'ValidationError'
        ) {
          const formattedErrors = error.reduce(
            (
              acc: { [field: string]: string },
              { pointer, message }: ValidationError,
            ) => {
              return { ...acc, [pointer]: message };
            },
            {},
          );

          setTouched({});
          setStatus(formattedErrors);
        } else if (error instanceof IbanExistsErrorClass) {
          setMutationError(error.message);
        } else {
          setMutationError(error);
        }
      } finally {
        if (isMounted.current) {
          setSubmitting(false);
          setFormikSubmitting(false);
        }
      }
    },
    [
      client,
      fieldProps,
      initialValues,
      isSubmitting,
      mutation,
      onSuccessCallback,
      readDocument,
      readDocumentFields,
      refetchQueries,
      schema,
      values,
      variables,
      formatBeforeSubmit,
      submitAllValues,
      onErrorCallback,
    ],
  );

  const _onBeforeSubmit = useCallback(
    async (newValues: any, formikHelpers: FormikHelpers<any>) => {
      if (!onBeforeSubmit) {
        onSubmit(newValues, formikHelpers);
      }
      try {
        const beforeSubmitValues = await onBeforeSubmit!(
          newValues,
          formikHelpers,
        );

        if (beforeSubmitValues === null) {
          return;
        }

        onSubmit(beforeSubmitValues, formikHelpers);
      } catch (error) {
        if (error && error instanceof Error) {
          setMutationError(error);
        }
      }
    },
    [onBeforeSubmit, onSubmit],
  );

  const yupSchema = useMemo(
    () =>
      buildNestedYupSchema(
        merge({}, validationSchema, validation),
        validationDependencies,
      ),
    [validationSchema, validation, validationDependencies],
  );

  const childs = renderChildren();

  const renderActions = useCallback(
    (_isSubmitting: boolean, isDirty: boolean, resetForm: any) => {
      if (isLoading) {
        if (startInEdit) {
          return (
            <>
              <Button secondary disabled />
              <Button disabled />
            </>
          );
        }
        return <Button secondary disabled />;
      }
      if (isEditing && !disableEdit) {
        return (
          <>
            {!hideCancel ? (
              <Button
                secondary
                onClick={() => onAbort(resetForm)}
                disabled={_isSubmitting}
                type="reset"
                className={isDirty ? 'red' : ''}
                data-testid="graphql-form-abort"
                key="abort"
              >
                {isDirty ? 'Verwerfen' : 'Abbrechen'}
              </Button>
            ) : null}
            {!hideSubmit ? (
              <FormikSubmit data-testid="graphql-form-submit">
                {labels && labels.submit ? labels.submit : 'Speichern'}
                {_isSubmitting ? '…' : ''}
              </FormikSubmit>
            ) : null}
          </>
        );
      }
      if (!disableEdit) {
        return (
          <Button
            data-testid="graphql-form-edit"
            secondary
            onClick={() => setIsEditing(true)}
            key="edit"
          >
            Bearbeiten
          </Button>
        );
      }
    },
    [
      disableEdit,
      isEditing,
      isLoading,
      labels,
      onAbort,
      startInEdit,
      hideCancel,
      hideSubmit,
    ],
  );

  const renderTestState = useCallback(
    (_isSubmitting: boolean, isValid: boolean) => {
      if (!['development', 'test'].includes(process.env.NODE_ENV)) return null;

      const getState = () => {
        if (isLoading) {
          return 'is-loading';
        }

        if (_isSubmitting) {
          return 'is-submitting';
        }

        if (!isValid) {
          return 'is-invalid';
        }
        if (isEditing) {
          return 'is-editing';
        }
        return 'is-ready';
      };

      return getState();
    },
    [isEditing, isLoading],
  );

  // useTraceUpdate({
  //   initialValues: formikInitialValues,
  //   validationSchema: yupSchema,
  //   onSubmit,
  //   mutationError,
  //   renderTestState,
  //   renderActions,
  // });

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={yupSchema}
      onSubmit={onBeforeSubmit ? _onBeforeSubmit : onSubmit}
      enableReinitialize
    >
      {({ dirty, isSubmitting: _isSubmitting, isValid, resetForm }) => (
        <Form
          className={[className, isEditing && 'is-editing'].join(' ')}
          data-testid={`graphql-form-${renderTestState(
            _isSubmitting,
            isValid,
          )}`}
          autoComplete="off"
        >
          {mutationError && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <div className="form-error">
              {Array.isArray(mutationError) ? (
                mutationError.map((error) => (
                  <ErrorMsg
                    key={error}
                    title="Fehler"
                    error={error}
                    retryable={false}
                  />
                ))
              ) : (
                <ErrorMsg
                  title="Fehler"
                  message={String(mutationError)}
                  retryable={false}
                />
              )}
            </div>
          )}
          {/* {errors !== null && errors !== undefined && JSON.stringify(errors)} */}
          <div className="form-content">{childs}</div>
          <ActionBtns className="action-buttons">
            {renderActions(_isSubmitting, dirty, resetForm)}
          </ActionBtns>
        </Form>
      )}
    </Formik>
  );
}

export default GraphQlForm;
