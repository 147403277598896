import * as React from 'react';
import { DateTime } from 'luxon';
import 'styled-components/macro';
import * as yup from 'yup';

import GraphqlForm from '../../../components/graphql-form';
import {
  AccountingWorkflowPayload,
  AccountingType,
  InvoiceCycle,
} from '../../../graphql-types';

import {
  CreateAccountingWorkflowFormProps,
  FormVariablesAccountingWorkflow,
} from './types';

const toUTC = (date: Date) =>
  DateTime.fromJSDate(date).setZone('utc').toFormat('yyyy-MM-dd');

export default function CreateAccountingWorkflowForm({
  onSuccess,
  onAbort,
  mutation,
  readDocumentsFields,
  formVariables,
  innerForm,
  innerFormType,
}: CreateAccountingWorkflowFormProps) {
  const {
    name,
    accountingTypes = [AccountingType.StandardAccounting],
    workflowType,
    contractToAccount,
    paymentPeriodStartAt,
    paymentPeriodEndAt,
    settlementDay,
    settlementMonth,
    invoiceCycle,
    quarterlyDeduction,
    contractStartDate,
    plantsToAccount = [],
  } = formVariables;
  const invoiceCycleValue = invoiceCycle ?? InvoiceCycle.Yearly;
  const defaultValues: FormVariablesAccountingWorkflow = {
    workflowType,
    name,
    ...(accountingTypes.length === 1 && {
      accountingType: accountingTypes[0],
    }),
    contractToAccount,
    // Conversion toUtc: despite the values come as Date, we work inside the form with strings because that's how graphql expect the values
    ...(paymentPeriodStartAt && {
      paymentPeriodStartAt: toUTC(paymentPeriodStartAt),
    }),
    ...(paymentPeriodEndAt && {
      paymentPeriodEndAt: toUTC(paymentPeriodEndAt),
    }),
    ...((invoiceCycleValue === InvoiceCycle.Yearly ||
      innerFormType === 'dashboard') && {
      settlementDay,
      settlementMonth,
    }),
    invoiceCycle: invoiceCycleValue,
    plantsToAccount,
    quarterlyDeduction,
    contractStartDate,
  };

  return (
    <GraphqlForm<Partial<AccountingWorkflowPayload>>
      mutation={mutation}
      startInEdit
      onSuccess={(result, add) => {
        if (onSuccess) {
          onSuccess(result, add);
        }
      }}
      onAbort={onAbort}
      readDocumentFields={readDocumentsFields}
      formVariables={formVariables}
      defaultValues={defaultValues}
      validation={{
        settlementDay: yup.number().required(),
        settlementMonth: yup.number().required(),
        name: yup.string().required(),
        plantsToAccount: yup
          .array()
          .of(yup.string())
          .when('workflowType', {
            is: 'multiple',
            then: yup.array().of(yup.string()).required(),
          }),
        accountingType: yup
          .string()
          .oneOf([
            'standardAccounting',
            'closingAccountingMove',
            'closingAccountingThird',
            'betweenAccounting',
            'correctionAccounting',
          ])
          .required(),
      }}
      labels={{
        submit: 'Erstellen',
      }}
    >
      {innerForm}
    </GraphqlForm>
  );
}
