import * as React from 'react';
import {
  ColumnDefinition,
  Icons,
  WorkflowStep,
} from '@ampeersenergy/ampeers-ui-components';
import styled, { useTheme } from 'styled-components/macro';

import { formatDate } from '../../helpers/formatStrings';
import { AccountingRunCurrentState, StepStatus } from '../../graphql-types';
import { useHasRole } from '../../components/useHasRole';

import { AccountingRunTable } from './AccountingRunTable';
import {
  getLabelForStepName,
  mapStepStatus,
} from './details/accountingRunHelper';

export const accountingRunTitle = 'Abrechnungsläufe';

const Content = styled.div`
  position: relative;
`;

const WorkflowStepContainer = styled.div`
  svg {
    width: 26px;
    height: 26px;
  }
`;

function WorkflowExecutionIcon({
  status,
}: {
  status: AccountingRunCurrentState;
}) {
  const theme = useTheme();
  const size = 30;
  switch (status) {
    case AccountingRunCurrentState.Complete:
      return (
        <Icons.Checkmark size={size} color={theme.palette.success.color} />
      );
    case AccountingRunCurrentState.NeedsAction:
      return <Icons.Progress size={size} />;
    case AccountingRunCurrentState.Error:
      return <Icons.Wrong size={size} color={theme.palette.error.color} />;
    default:
      return <Icons.Help size={size} color={theme.palette.borderMuted} />;
  }
}

export type AccountingRunColumns = {
  WorkflowId: string;

  AccountingRunState: string;
  AccountingRunName: string;
  AccountingRunStartTime: string;
  AccountingRunPeriodStart: string;
  AccountingRunPeriodEnd: string;
  AccountingRunSettlementDay: string;
  AccountingRunStep: string;
  AccountingRunStepState: StepStatus;
  AccountingRunSessionId?: string | null;
  AccountingRunDownpaymentStartDate?: string | null;
  AccountingRunOpcName?: string | null;
  AccountingRunContractLabels?: string[] | null;
  AccountingRunCustomerLabels?: string[] | null;
  AccountingRunEndTime?: string | null;
};

export const disableSortAndFilter = {
  filterable: false,
  disableSortBy: true,
};

const baseColumns: ColumnDefinition<AccountingRunColumns>[] = [
  {
    Header: 'Status',
    accessor: 'AccountingRunState',
    Cell: ({ value }) => <WorkflowExecutionIcon status={value} />,
    filterable: false,
    style: { textAlign: 'center' },
  },
  {
    Header: 'Name',
    accessor: 'AccountingRunName',
    type: 'string',
  },
  {
    Header: 'Gestartet',
    accessor: 'AccountingRunStartTime',
    type: 'date',
  },
  {
    Header: 'Beendet',
    accessor: 'AccountingRunEndTime',
    type: 'date',
  },
  {
    Header: 'Rechnungsperiode',
    accessor: 'AccountingRunPeriodStart',
    Cell: ({ cell }) => {
      const start = cell.value ?? '';
      const end = cell.row.original.AccountingRunPeriodEnd ?? '';
      return `${formatDate(start)} - ${formatDate(end)}`;
    },
  },
  {
    Header: 'Stichtag',
    accessor: 'AccountingRunSettlementDay',
    type: 'string',
  },
  {
    Header: 'Abschlagsbeginn',
    accessor: 'AccountingRunDownpaymentStartDate',
    type: 'date',
  },
  {
    Header: 'Schritt',
    accessor: 'AccountingRunStep',

    Cell: ({ cell }) => {
      const state = mapStepStatus(cell.row.original.AccountingRunStepState);
      const label = getLabelForStepName(cell.value);

      return (
        <WorkflowStepContainer>
          <WorkflowStep
            label={label}
            state={state}
            onClick={() => null}
            isSelected={false}
            key={cell.row.original.WorkflowId}
          />
        </WorkflowStepContainer>
      );
    },
  },
];

export function AccountingRunOverview() {
  const { hasRole: operationsRole } = useHasRole('ae-operations');

  const operationsColumns: ColumnDefinition<AccountingRunColumns>[] = [
    ...baseColumns,
    {
      Header: 'OPC',
      accessor: 'AccountingRunOpcName',
      type: 'string',
    },
    {
      Header: 'Session',
      accessor: 'AccountingRunSessionId',
      type: 'string',
    },
  ];

  const columns = operationsRole ? operationsColumns : baseColumns;

  const searchColumns: ColumnDefinition<AccountingRunColumns>[] = [
    ...columns,
    {
      Header: 'Vertragsnummer',
      accessor: 'AccountingRunContractLabels',
      hidden: true,
    },
    {
      Header: 'Kundennummer',
      accessor: 'AccountingRunCustomerLabels',
      hidden: true,
    },
  ];

  return (
    <Content>
      <AccountingRunTable columns={searchColumns} />
    </Content>
  );
}
