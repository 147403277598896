import React from 'react';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';

import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import { GraphqlFormField } from '../../../components/graphql-form/render';
import { InvoiceCycle } from '../../../graphql-types';

import { getClosestDatetoEndDate, getLastSettlementDate } from './helpers';
import { FormVariablesAccountingWorkflow } from './types';

export default function ClosingAccountingForm() {
  const { formVariables } = useGraphqlForm();
  const {
    contractStartDate,
    lastAccountingPeriodEnd,
    settlementDay,
    settlementMonth,
  } = formVariables;
  const { setFieldValue, values } =
    useFormikContext<FormVariablesAccountingWorkflow>();
  const { invoiceCycle, paymentPeriodEndAt } = values;
  const contractEndDate = paymentPeriodEndAt;

  React.useEffect(() => {
    // We actually only need the settlement day and month for invoices with a yearly cycle.
    // However, both settlement day and month are required for starting an accounting run.
    // In addition to that, we show the settlementDate in the accounting run table so it would
    // be inconsistent for the users if the settlement day and month would be set to a random value
    // which is why we set it to the paymentPeriodEndAt day and month for invoice cycle != Yearly.
    if (invoiceCycle === InvoiceCycle.Yearly || !paymentPeriodEndAt) {
      setFieldValue('settlementDay', settlementDay);
      setFieldValue('settlementMonth', settlementMonth);
    } else {
      const paymentDate = DateTime.fromISO(paymentPeriodEndAt);
      setFieldValue('settlementDay', paymentDate.day);
      setFieldValue('settlementMonth', paymentDate.month);
    }
  }, [
    setFieldValue,
    invoiceCycle,
    settlementDay,
    settlementMonth,
    paymentPeriodEndAt,
  ]);

  React.useEffect(() => {
    // This calculation is to find which is the start accounting date for the closing accounting move (Schlussrechnung)
    if (contractEndDate && contractStartDate && invoiceCycle) {
      const contractEndDatetime = DateTime.fromISO(contractEndDate, {
        zone: 'utc',
      });
      // Get last accounting end date
      const lastAccountingPeriodEndDatetime = DateTime.fromJSDate(
        lastAccountingPeriodEnd,
        { zone: 'utc' },
      );
      // Get initial Contract date
      const contractStartDatetime = DateTime.fromJSDate(contractStartDate, {
        zone: 'utc',
      });

      // Get settlement Date
      const settlementDatetime =
        getLastSettlementDate(
          contractEndDatetime,
          invoiceCycle,
          settlementMonth,
          settlementDay,
        ) || contractStartDatetime;

      const startClosingAccountingDate = getClosestDatetoEndDate(
        contractEndDatetime,
        [
          contractStartDatetime,
          lastAccountingPeriodEndDatetime.plus({ day: 1 }),
          settlementDatetime,
        ],
      );
      if (startClosingAccountingDate) {
        setFieldValue(
          'paymentPeriodStartAt',
          startClosingAccountingDate.toISODate(),
        );
      }
    }
  }, [
    settlementMonth,
    settlementDay,
    setFieldValue,
    contractEndDate,
    lastAccountingPeriodEnd,
    contractStartDate,
    invoiceCycle,
  ]);

  return (
    <>
      <GraphqlFormField
        name="paymentPeriodStartAt"
        label="Start Abrechnungsperiode"
        disabled
      />
      <GraphqlFormField
        name="paymentPeriodEndAt"
        label="Ende Abrechnungsperiode"
        disabled
      />
    </>
  );
}
