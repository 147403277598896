import {
  ButtonIcon,
  defaultTheme,
  DestructiveActionConfirmation,
  Flex,
  FlexRow,
  Icons,
  LinkButton,
  PageTitleLayout,
} from '@ampeersenergy/ampeers-ui-components';
import { DateTime } from 'luxon';
import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ErrorMsg } from '../../components';
import { ContractNameLabel } from '../../components/contractNameLabel';
import { Card, WorkflowCard } from '../../components/workflowCard';
import {
  ChildWorkflowResult,
  ContractUpdateResult,
  GetSupplierChangeWorkflowStateDocument,
  GetSupplierChangeWorkflowStateQuery,
  StepStatus,
  SupplierChangeWorkflowType,
  useGetSupplierChangeWorkflowStateQuery,
  usePlantDetailQuery,
  useReadContractQuery,
  useRestartWorkflowMutation,
} from '../../graphql-types';
import SuccessResult from '../accounting/oldWorkflow/successResult';

import { ChildWorkflow } from './steps/ChildWorkflow';
import { SelectNetworkOperator } from './steps/selectNetworkOperator';
import { SendDocument } from './steps/sendDocument';
import { UpdateContract } from './steps/updateContract';
import WaitForAnswer from './steps/waitForAnswer';

const ContractCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  min-height: 80px;
`;

const WorkflowSuccess = styled(SuccessResult)`
  margin-bottom: 15px;
`;

const RestartButtonIcon = styled(ButtonIcon)`
  &:hover svg {
    fill: ${(props) => props.theme.palette.error.color};
  }
`;

const POLL_INTERVAL = 15 * 1000;

export const SupplierDetailsWithRouter = withRouter(SupplierDetails);

const contentLoaderProps = {
  speed: 2,
  backgroundColor: defaultTheme.palette.backgroundMuted,
  foregroundColor: defaultTheme.palette.border,
  style: { width: '100%', marginTop: '6px' },
  height: 14,
};

type Step = NonNullable<
  GetSupplierChangeWorkflowStateQuery['getSupplierChangeWorkflowState']
>['steps'][0];
type WorkflowResult = NonNullable<
  NonNullable<
    GetSupplierChangeWorkflowStateQuery['getSupplierChangeWorkflowState']
  >['steps'][0]
>['result'];
type NetworkOperatorResult = Extract<
  NonNullable<WorkflowResult>,
  { __typename: 'NetworkOperatorResult' }
>;

function SupplierDetails({ match }: { match: any }) {
  const { id: workflowId } = match.params;
  const [showResetModal, setShowResetModal] = React.useState(false);

  const { data, loading, startPolling, stopPolling } =
    useGetSupplierChangeWorkflowStateQuery({
      variables: { workflowId },
    });

  const isDone = data?.getSupplierChangeWorkflowState.done;

  const [restartWorkflow, { loading: restartWorkflowLoading }] =
    useRestartWorkflowMutation({
      variables: { workflowId },
      refetchQueries: [
        {
          query: GetSupplierChangeWorkflowStateDocument,
          variables: {
            workflowId,
          },
        },
      ],
    });

  const contractId = data?.getSupplierChangeWorkflowState.contractId;

  const workflowType = getWorkflowTypeName(
    data?.getSupplierChangeWorkflowState.type,
  );

  const startWorkflowDate = data?.getSupplierChangeWorkflowState.startedAt
    ? DateTime.fromISO(data?.getSupplierChangeWorkflowState.startedAt).toFormat(
        'dd.MM.yyyy',
      )
    : undefined;

  const { data: contractData } = useReadContractQuery({
    variables: { contractId: contractId! },
    skip: !contractId,
  });
  const customerLabel = contractData?.readContract?.customer.label;

  const { data: plantData } = usePlantDetailQuery({
    variables: { plantId: String(contractData?.readContract?.plantId) },
    skip: contractData?.readContract?.plantId === undefined,
  });

  function isNetworkOperatorResult(
    result: WorkflowResult,
  ): result is NetworkOperatorResult {
    return result.__typename === 'NetworkOperatorResult';
  }

  // TODO: why doesn't the type predicate work?
  const networkOperatorResult = data?.getSupplierChangeWorkflowState.steps
    .map((s) => s.result)
    .find((r) => isNetworkOperatorResult(r)) as
    | NetworkOperatorResult
    | undefined;

  const email =
    data?.getSupplierChangeWorkflowState?.type ===
    SupplierChangeWorkflowType.Cancellation
      ? networkOperatorResult?.oldSupplier?.email
      : networkOperatorResult?.gridOperator?.email;
  const phoneNumber =
    data?.getSupplierChangeWorkflowState?.type ===
    SupplierChangeWorkflowType.Cancellation
      ? networkOperatorResult?.oldSupplier?.phone
      : networkOperatorResult?.gridOperator?.phone;

  const updateContractResult = data?.getSupplierChangeWorkflowState.steps
    .map((s) => s.result)
    .find((r) => r.__typename === 'ContractUpdateResult') as
    | ContractUpdateResult
    | undefined;

  const name = contractData?.readContract?.customer.person.name;

  const contractLabel = contractData?.readContract?.label || '';

  React.useEffect(() => {
    startPolling(POLL_INTERVAL);

    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const cards = React.useMemo(() => {
    const deadline = updateContractResult?.deadline
      ? new Date(updateContractResult?.deadline)
      : undefined;

    const steps = data?.getSupplierChangeWorkflowState?.steps;
    if (steps) {
      return [...steps]
        .sort((step1, step2) => step1.index - step2.index)
        .map((step) => {
          return contractId ? (
            <React.Fragment key={step.index}>
              {renderCard(
                step,
                workflowId,
                contractId,
                customerLabel,
                data?.getSupplierChangeWorkflowState.type,
                { email, deadline, phoneNumber },
              )}
            </React.Fragment>
          ) : (
            <ErrorMsg error="No contractId found" />
          );
        });
    }
  }, [
    updateContractResult?.deadline,
    data?.getSupplierChangeWorkflowState?.steps,
    data?.getSupplierChangeWorkflowState.type,
    contractId,
    workflowId,
    customerLabel,
    email,
    phoneNumber,
  ]);

  const contractLink =
    contractData?.readContract && plantData?.readPlant
      ? `/project/${plantData?.readPlant?.projectId}/plant/${plantData?.readPlant?.id}/tenant/contract/${contractId}`
      : '#';

  return (
    <>
      <PageTitleLayout
        levelsUpToParent={1}
        title={`${name} • ${workflowType}`}
        isLoading={false}
      >
        <FlexRow css="max-width: 1030px; gap: 25px;">
          {loading || restartWorkflowLoading ? (
            <WorkflowCard state={StepStatus.WaitingForUser} title="">
              <ContentLoader {...contentLoaderProps}>
                <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
              </ContentLoader>
            </WorkflowCard>
          ) : (
            <Flex>{cards}</Flex>
          )}

          <Flex>
            <ContractCard>
              <Flex>
                <SidebarItem label={<>Vertrag</>}>
                  {name ? (
                    <Link to={contractLink}>
                      <ContractNameLabel
                        contractName={name}
                        contractLabel={contractLabel}
                      />
                    </Link>
                  ) : (
                    <ContentLoader {...contentLoaderProps}>
                      <rect
                        x="0"
                        y="0"
                        rx="3"
                        ry="3"
                        width="100%"
                        height="100%"
                      />
                    </ContentLoader>
                  )}
                </SidebarItem>
                <SidebarItem label={<>Gestartet am</>}>
                  {startWorkflowDate}
                </SidebarItem>
              </Flex>
              {!isDone && (
                <div>
                  <RestartButtonIcon
                    icon={Icons.Refresh}
                    secondary
                    onClick={() => setShowResetModal(true)}
                    title="Reset Workflow to the start"
                  />
                  <DestructiveActionConfirmation
                    action="zurücksetzen"
                    item="Vorgang"
                    isOpen={showResetModal}
                    onAbort={() => setShowResetModal(false)}
                    onConfirm={() => {
                      restartWorkflow();
                      setShowResetModal(false);
                    }}
                  >
                    Bist Du sicher, dass Du den Vorgang zurücksetzen willst?
                  </DestructiveActionConfirmation>
                </div>
              )}
            </ContractCard>
          </Flex>
        </FlexRow>
      </PageTitleLayout>
    </>
  );
}

type RenderCardInput = {
  email?: string | null;
  deadline?: Date;
  phoneNumber?: string | null;
};

function renderCard(
  step: Step,
  workflowId: string,
  contractId: string,
  customerLabel?: string,
  type?: SupplierChangeWorkflowType,
  input: RenderCardInput = {},
) {
  switch (step.result.__typename) {
    case 'ChildWorkflowResult':
      return (
        <ChildWorkflowCard
          workflowId={workflowId}
          contractId={contractId}
          state={step.status}
          result={step.result}
        />
      );
    case 'NetworkOperatorResult':
      return (
        <NetworkOperatorCard
          workflowId={workflowId}
          contractId={contractId}
          networkOperatorResult={step.result}
          state={step.status}
          type={type}
        />
      );
    case 'DocumentResult':
      return (
        <DocumentCard
          workflowId={workflowId}
          contractId={contractId}
          state={step.status}
          email={input.email}
          type={type}
        />
      );
    case 'ContractUpdateResult':
      return (
        <UpdateContractCard
          workflowId={workflowId}
          contractId={contractId}
          customerLabel={customerLabel}
          state={step.status}
          contractUpdateResult={step.result}
          deadline={input.deadline}
          phoneNumber={input.phoneNumber ?? undefined}
          type={type}
        />
      );
    default:
      return null;
  }
}

function ChildWorkflowCard({
  workflowId,
  contractId,
  state,
  result,
}: {
  workflowId: string;
  contractId: string;
  state: StepStatus;
  result: ChildWorkflowResult;
}) {
  return (
    <FlexRow>
      <WorkflowCard state={state} title="Kündigung des alten Vertrags">
        <ChildWorkflow
          contractId={contractId}
          workflowId={workflowId}
          result={result}
        />
      </WorkflowCard>
    </FlexRow>
  );
}

function getNetworkOperatorCardTitle(type?: SupplierChangeWorkflowType) {
  switch (type) {
    case SupplierChangeWorkflowType.Cancellation:
      return 'Alten Lieferanten auswählen';
    case SupplierChangeWorkflowType.SupplierChangeLocalSupply:
      return 'Messstellenbetreiber und Netzbetreiber auswählen';
    case SupplierChangeWorkflowType.SupplierChangeThirdParty:
      return 'Netzbetreiber auswählen';
    default:
      return '';
  }
}

function NetworkOperatorCard({
  workflowId,
  contractId,
  networkOperatorResult,
  state,
  type,
}: {
  workflowId: string;
  contractId: string;
  networkOperatorResult: NetworkOperatorResult;
  state: StepStatus;
  type?: SupplierChangeWorkflowType;
}) {
  const title = getNetworkOperatorCardTitle(type);

  return (
    <FlexRow>
      <WorkflowCard state={state} title={title}>
        <SelectNetworkOperator
          contractId={contractId}
          workflowId={workflowId}
          state={state}
          type={type}
          meteringPointOperatorName={
            networkOperatorResult.meteringPointOperator?.companyName
          }
          gridOperatorName={networkOperatorResult.gridOperator?.companyName}
          oldSupplierName={networkOperatorResult.oldSupplier?.companyName}
        />
      </WorkflowCard>
    </FlexRow>
  );
}

function getDocumentCardTitle(type?: SupplierChangeWorkflowType) {
  switch (type) {
    case SupplierChangeWorkflowType.Cancellation:
      return 'Versenden des Kündigungsschreibens per E-Mail';
    case SupplierChangeWorkflowType.SupplierChangeLocalSupply:
      return 'Versenden der Abmeldung der Marktlokation per E-Mail';
    case SupplierChangeWorkflowType.SupplierChangeThirdParty:
      return 'Versenden der Anmeldung der Marktlokation per E-Mail';
    default:
      return '';
  }
}

function DocumentCard({
  state,
  workflowId,
  contractId,
  type,
  email,
}: {
  state: StepStatus;
  workflowId: string;
  contractId: string;
  type?: SupplierChangeWorkflowType;
  email?: string | null;
}) {
  const title = getDocumentCardTitle(type);
  return (
    <FlexRow>
      <WorkflowCard state={state} title={title}>
        <SendDocument
          workflowId={workflowId}
          contractId={contractId}
          state={state}
          type={type}
          email={email}
        />
      </WorkflowCard>
    </FlexRow>
  );
}

function UpdateContractCard({
  contractUpdateResult,
  state,
  workflowId,
  contractId,
  customerLabel,
  deadline,
  type,
  phoneNumber,
}: {
  contractUpdateResult: ContractUpdateResult;
  state: StepStatus;
  workflowId: string;
  contractId: string;
  customerLabel?: string;
  deadline?: Date;
  type?: SupplierChangeWorkflowType;
  phoneNumber?: string;
}) {
  const updateState =
    state === StepStatus.WaitingForExternal || state === StepStatus.Timeout
      ? StepStatus.WaitingForUser
      : state;

  return (
    <>
      <FlexRow>
        <WorkflowCard state={state} title="Warten auf Antwort">
          <WaitForAnswer
            deadline={deadline}
            status={state}
            phoneNumber={phoneNumber}
            type={type}
          />
        </WorkflowCard>
      </FlexRow>
      <FlexRow>
        <WorkflowCard state={updateState} title="Vertrag Aktualisieren">
          <UpdateContract
            contractId={contractId}
            customerLabel={customerLabel}
            workflowId={workflowId}
            deliveryDate={contractUpdateResult.contractDate ?? undefined}
            newMalo={contractUpdateResult.newMalo ?? undefined}
            reduction={
              contractUpdateResult.reductionValue?.toString() ?? undefined
            }
            reductionStartDate={
              contractUpdateResult.reductionStartDate ?? undefined
            }
            type={type}
          />
        </WorkflowCard>
      </FlexRow>
      {state === StepStatus.Succeeded && (
        <FlexRow>
          <Card>
            <WorkflowSuccess type="Prozess" />
            {contractUpdateResult?.parentWorkflowId ? (
              <div css="display: flex; justify-content: center;">
                <LinkButton
                  as={Link}
                  to={`/supplier/workflow/${contractUpdateResult.parentWorkflowId}`}
                >
                  Zurück zum Lieferantenwechsel Prozess
                </LinkButton>
              </div>
            ) : null}
          </Card>
        </FlexRow>
      )}
    </>
  );
}

const SiderbarHeadline = styled.div`
  font-weight: bold;
  margin-bottom: 2px;
`;

const SidebarWrapper = styled.div`
  margin-bottom: 10px;
  width: fit-content;
`;

const SidebarItem: React.FC<{ label: React.ReactNode }> = ({
  label,
  children,
}) => {
  return (
    <SidebarWrapper>
      <SiderbarHeadline>{label}</SiderbarHeadline>
      {children}
    </SidebarWrapper>
  );
};

function getWorkflowTypeName(type?: SupplierChangeWorkflowType) {
  switch (type) {
    case SupplierChangeWorkflowType.SupplierChangeLocalSupply:
      return 'Wechsel in den Mieterstrom';
    case SupplierChangeWorkflowType.Cancellation:
      return 'Kündigung beim alten Lieferanten';
    case SupplierChangeWorkflowType.SupplierChangeThirdParty:
      return 'Wechsel in die Drittbelieferung';
    default:
      return '';
  }
}
