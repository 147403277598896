import { CrmBoardType } from './presentations/crm-board/crm-board.types';

export const CRM_DATA_MOCK: CrmBoardType = {
  id: '1',
  columns: [
    {
      id: 1,
      title: 'Backlog',
      cards: [
        {
          id: 1,
          columnId: 1,
          firstName: 'Peter',
          lastName: 'Pan',
          street: 'Linprunstrasse',
          streetNo: '16',
          zipCode: '80335',
          city: 'München',
          expectedConsumption: 1600,
          created: new Date('2019-03-12'),
          mail: 'peter@pan.co.uk',
          phone: '0123-456 78 90-12',
          status: 'okay',
        },
        {
          id: 5,
          columnId: 1,
          firstName: 'Clark',
          lastName: 'Kent',
          street: '344 Clinton Street',
          streetNo: '',
          zipCode: '',
          city: 'New York City',
          expectedConsumption: 12,
          created: new Date('1938-06-01'),
          status: 'overdue',
        },
        {
          id: 3,
          columnId: 1,
          firstName: 'Diana',
          lastName: 'Prince',
          street: 'West 20th Street',
          streetNo: '',
          zipCode: '2890',
          city: 'Washington D.C.',
          expectedConsumption: 23456,
          created: new Date('1963-07-23'),
          phone: 'Capitol 7-362',
          status: 'intermediate',
        },
      ],
    },
    {
      id: 2,
      title: 'Doing',
      cards: [
        {
          id: 2,
          columnId: 2,
          firstName: 'Tony',
          lastName: 'Stark',
          street: 'Malibu Point',
          streetNo: '10880',
          zipCode: '90265',
          city: 'Malibu',
          expectedConsumption: 1230,
          created: new Date('2008-05-01'),
          mail: 'tonystark@starkenterprises.com',
          status: 'overdue',
        },
      ],
    },
    {
      id: 3,
      title: 'Done',
      cards: [
        {
          id: 4,
          columnId: 3,
          firstName: 'Peter',
          lastName: 'Parker',
          street: '15th Street',
          streetNo: '',
          zipCode: '',
          city: 'New York City',
          expectedConsumption: 1400,
          created: new Date('2020-06-10'),
        },
      ],
    },
  ],
};
