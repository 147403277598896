import { useToasts } from '@ampeersenergy/ampeers-ui-components';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import {
  Status,
  useGetAllScheduleRequestsResultsWfmLazyQuery,
  useGetMultipleScheduleRequestsResultsWfmQuery,
} from '../../graphql-types';
import { useSession } from '../../services/session';

import { getJobVerb, translateJobName } from './taskManagerHelper';

const POLLING_INTERVAL = 1500;

export const TaskManagerWFMContext = React.createContext<{
  addJobToTaskManager: (jobId: string, type: string) => void;
  removeJob: (jobId: string) => void;
  watchJob: (jobId: string) => Status | undefined;
}>({
  addJobToTaskManager: () => {},
  removeJob: () => {},
  watchJob: () => undefined,
});

export const useTaskManagerWFM = () => React.useContext(TaskManagerWFMContext);

export default function TaskManagerProviderWFM({
  children,
}: {
  children: React.ReactNode;
}) {
  const history = useHistory();
  const { hasSession } = useSession();

  const [activeJobs, setActiveJobs] = React.useState<
    Record<string | number, React.ReactText | undefined>
  >({});

  const jobIds = React.useMemo(() => Object.keys(activeJobs), [activeJobs]);

  const toast = useToasts();

  // TODO: replace with functionality from Workflow Manager implementation of getMultipleScheduleRequestsResults
  const { data, startPolling, stopPolling } =
    useGetMultipleScheduleRequestsResultsWfmQuery({
      variables: { jobIds },
      skip: !hasSession || jobIds.length === 0,
    });

  const firstRender = React.useRef(true);
  const checkedAllJobs = React.useRef(false);
  const [getAllScheduleRequests, { data: allScheduleRequests }] =
    useGetAllScheduleRequestsResultsWfmLazyQuery();

  React.useEffect(() => {
    if (
      allScheduleRequests?.getAllScheduleRequestsResultsWFM?.__typename ===
        'MultipleScheduleRequestsSuccess' &&
      checkedAllJobs.current === false
    ) {
      const _activeJobs =
        allScheduleRequests.getAllScheduleRequestsResultsWFM.results
          .filter(
            (job) =>
              job.status === Status.InProgress || job.status === Status.Active,
          )
          .map((job) => job.jobId);

      setActiveJobs((prevActiveJobs) => ({
        ...prevActiveJobs,
        ..._activeJobs.reduce(
          (acc, jobId) => ({
            ...acc,
            [jobId]: prevActiveJobs[jobId] ?? undefined,
          }),
          {},
        ),
      }));

      checkedAllJobs.current = true;
    }
  }, [allScheduleRequests]);

  React.useEffect(() => {
    if (firstRender && hasSession) {
      getAllScheduleRequests({});
      firstRender.current = false;
    }
  }, [getAllScheduleRequests, hasSession]);

  // Start polling when there are jobs to poll or stop polling when no jobs exist
  React.useEffect(() => {
    if (jobIds.length > 0) {
      startPolling(POLLING_INTERVAL);
    } else {
      stopPolling();
    }
  }, [jobIds, startPolling, stopPolling]);

  React.useEffect(() => {
    // This makes sure that we stop polling when the component is unmounted.
    return () => {
      stopPolling();
    };
  }, [stopPolling]);

  // When user is no longer logged in but there are active jobs
  // we stopPolling and dismiss all toast messages
  React.useEffect(() => {
    if (!hasSession && activeJobs) {
      stopPolling();
      Object.keys(activeJobs).forEach((key) => {
        const id = activeJobs[key];
        if (id) {
          toast.dismiss(id);
        }
      });
    }
  }, [hasSession, stopPolling, activeJobs, toast]);

  const removeJob = React.useCallback((jobId: string) => {
    setActiveJobs((prevActiveJobs) => {
      const { [jobId]: toastId, ...rest } = prevActiveJobs;
      return rest;
    });
  }, []);

  const updateJob = React.useCallback(
    (jobId: string, toastId?: React.ReactText) => {
      setActiveJobs((prevActiveJobs) => ({
        ...prevActiveJobs,
        [jobId]: toastId,
      }));
    },
    [],
  );

  const addJobToTaskManager = React.useCallback(
    (jobId: string, type: string) => {
      if (jobIds.includes(jobId)) return;

      const toastId = toast.loading(
        `${translateJobName(type)} werden ${getJobVerb(type)}...`,
      );
      updateJob(jobId, toastId);
    },
    [jobIds, toast, updateJob],
  );

  const watchJob = React.useCallback(
    (jobId: String) => {
      if (
        data?.getMultipleScheduleRequestsResultsWFM?.__typename ===
        'MultipleScheduleRequestsSuccess'
      ) {
        const job = data.getMultipleScheduleRequestsResultsWFM.results.find(
          (result) => result.jobId === jobId,
        );
        return job?.status;
      }
    },
    [data],
  );

  const toastAction = React.useCallback(
    (jobId: string, remove = true) => {
      history.push(`/tasks/${jobId}`);
      if (remove) {
        removeJob(jobId);
      }
    },
    [history, removeJob],
  );

  React.useEffect(() => {
    if (
      !data ||
      data.getMultipleScheduleRequestsResultsWFM?.__typename !==
        'MultipleScheduleRequestsSuccess'
    )
      return;

    data.getMultipleScheduleRequestsResultsWFM?.results?.forEach((job) => {
      let toastId = activeJobs[job.jobId];
      const toastExisted = !!toastId;

      const jobName = translateJobName(job.jobName);
      const jobVerb = getJobVerb(job.jobName);

      if (
        job.status === Status.Failed ||
        job.status === Status.CompletedWithErrors
      ) {
        toastId = toast.createOrUpdate(
          toastId,
          `Bei der Verarbeitung der ${jobName} ist ein Fehler aufgetreten`,
          {
            type: 'error',
            closeOnClick: true,
            onClick: () => toastAction(job.jobId),
            closeButton: true,
          },
        );
      } else if (job.status === Status.Completed) {
        const noOperationError = job.result?.every(
          (res) => res.data.__typename !== 'OperationError',
        );

        if (!noOperationError) {
          toastId = toast.createOrUpdate(
            toastId,
            `Es konnten nicht alle ${jobName} erfolgreich ${jobVerb} werden`,
            {
              type: 'warning',
              closeOnClick: true,
              onClick: () => toastAction(job.jobId),
              closeButton: true,
            },
          );
        } else {
          toastId = toast.createOrUpdate(
            toastId,
            `${jobName} erfolgreich ${jobVerb}`,
            {
              type: 'success',
              closeOnClick: true,
              onClick: () => toastAction(job.jobId),
              closeButton: true,
            },
          );
        }
      } else if (
        job.status === Status.InProgress ||
        job.status === Status.Active
      ) {
        toastId = toast.createOrUpdate(
          toastId,
          `${jobName} werden ${jobVerb} (${job.progress}/${job.total})`,
          {
            isLoading: true,
            onClick: () => toastAction(job.jobId, false),
            closeOnClick: true,
            closeButton: true,
          },
        );
      }
      if (toastId && !toastExisted) {
        updateJob(job.jobId, toastId);
      }
    });
  }, [removeJob, updateJob, data, stopPolling, toast, activeJobs, toastAction]);

  const values = React.useMemo(
    () => ({
      addJobToTaskManager,
      removeJob,
      watchJob,
    }),
    [addJobToTaskManager, removeJob, watchJob],
  );

  return (
    <TaskManagerWFMContext.Provider value={values}>
      {children}
    </TaskManagerWFMContext.Provider>
  );
}
