import React from 'react';
import styled, { withTheme, CSSProperties } from 'styled-components';
import {
  matchPath,
  withRouter,
  Link,
  RouteComponentProps,
} from 'react-router-dom';

import { Themeprops } from '../themeProvider';
import Ripples from '../ripple';

const rippleStyle: CSSProperties = {
  backgroundColor: '#fff',
  border: 'none',
  fontWeight: 'bold',
  fontSize: '13px',
  padding: '14px',
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  boxShadow: 'none',
  borderRadius: '4px',
  color: '#4C4C4C',
};

// const Label = styled.div`
//   margin-left: 10px;
//   font-size: 15px;
//   margin-right: 5px;
// `;

const Wrapper = styled.div`
  margin: 5px 10px;
  cursor: pointer;
`;

interface NavigationItemProps {
  icon?: any;
  label: any;
  href?: string;
  onClick?: () => any;
  isActive?: boolean;
  onlyIconActive?: boolean;
  exact?: boolean;
}

function NavigationItem(
  props: NavigationItemProps & Themeprops & RouteComponentProps,
) {
  const {
    icon,
    theme,
    location,
    href,
    onClick,
    label,
    isActive,
    onlyIconActive,
    exact,
  } = props;
  const IconComp = icon;
  const macthingRoute =
    isActive ||
    matchPath(location.pathname, {
      path: href,
      exact: exact || false,
    });

  const nextStyle = {
    color: theme.primaryColor,
    backgroundColor: theme.secondaryColor,
  };

  const style = {
    ...rippleStyle,
    ...(macthingRoute !== null && !onlyIconActive ? nextStyle : {}),
  };

  const inner = (
    <Ripples style={style} nextStyle={nextStyle} persist>
      <IconComp
        size="25"
        color={macthingRoute !== null ? theme.primaryColor : '#666666'}
      />
      {/* <Label>{label}</Label> */}
    </Ripples>
  );

  return (
    <Wrapper>
      {onClick ? (
        <div
          onClick={onClick}
          title={label}
          onKeyPress={onClick}
          role="button"
          tabIndex={0}
        >
          {inner}
        </div>
      ) : (
        // @ts-ignore
        <Link to={href} title={label}>
          {inner}
        </Link>
      )}
    </Wrapper>
  );
}

export default withTheme(withRouter(NavigationItem));
