import * as React from 'react';
import {
  BooleanFilter,
  ColumnDefinition,
  FetchDataInput,
  Filter,
  Icons,
  Table,
  TableCells,
} from '@ampeersenergy/ampeers-ui-components';
import { DateTime } from 'luxon';

import {
  AccountingRunCurrentState,
  AccountingRunSearchAttributes,
  AccountingRunSearchSorting,
  AccountingRunSortByOptions,
  SortDirection,
  useGetAccountingRunWorkflowsQuery,
} from '../../graphql-types';

import type { AccountingRunColumns } from './AccountingRunOverview';

const POLL_INTERVAL = 15 * 1000;

function buildSearchAttributesFromFilters(filters?: Filter[]) {
  if (!filters) return undefined;

  return filters.reduce((acc, curr) => {
    const dateSearchAttributes = [
      'AccountingRunStartTime',
      'AccountingRunPeriodStart',
      'AccountingRunPeriodEnd',
      'AccountingRunDownpaymentStartDate',
      'AccountingRunEndTime',
    ];
    const value = dateSearchAttributes.includes(curr.id)
      ? getDateTimeFromString(curr.value)
      : curr.value;

    if (value) {
      return {
        ...acc,
        [curr.id]: value,
      };
    }
    return acc;
  }, {} as AccountingRunSearchAttributes);
}

function getDateTimeFromString(value: string) {
  const date = DateTime.fromFormat(value, 'dd.MM.yyyy', { zone: 'utc' });
  if (date.isValid) {
    return date.toISO();
  }
  return null;
}

const booleanFilters: BooleanFilter[] = [
  {
    id: 'AccountingRunState',
    value: AccountingRunCurrentState.Complete,
    label: (active) => (
      <>
        Abgeschlossene
        <Icons.Checkmark size={20} color={active ? '#fff' : 'primary'} />
      </>
    ),
  },
  {
    id: 'AccountingRunState',
    value: AccountingRunCurrentState.NeedsAction,
    label: (active) => (
      <>
        Laufende
        <Icons.Progress size={20} color={active ? '#fff' : 'primary'} />
      </>
    ),
  },
  {
    id: 'AccountingRunState',
    value: AccountingRunCurrentState.Error,
    label: (active) => (
      <>
        Fehlerhafte
        <Icons.Help size={20} color={active ? '#fff' : 'primary'} />
      </>
    ),
  },
];

interface AccountingRunTableProps {
  columns: ColumnDefinition<AccountingRunColumns>[];
}

export function AccountingRunTable({ columns }: AccountingRunTableProps) {
  const pageSize = 10;
  const [currentPage, setCurrentPage] = React.useState(0);
  const [nextPageToken, setNextPageToken] = React.useState('');

  const [pageTokens, setPageTokens] = React.useState<Array<string | undefined>>(
    [],
  );
  const [sorting, setSorting] = React.useState<
    AccountingRunSearchSorting | undefined
  >();

  const [tableFilters, setTableFilters] = React.useState<
    Filter[] | undefined
  >();

  const rowLink = React.useCallback(
    ({ row }: TableCells<AccountingRunColumns>) => {
      const entry = row.original;
      return `/accounting/runs/${entry.WorkflowId}`;
    },
    [],
  );

  const { data, loading, refetch, startPolling, stopPolling } =
    useGetAccountingRunWorkflowsQuery({
      variables: {
        nextPageToken,
        searchAttributes: buildSearchAttributesFromFilters(tableFilters),
        sorting,
        pageSize,
      },
    });

  const getWorkflowsResponse = data?.getAccountingRunWorkflows;

  React.useEffect(() => {
    setPageTokens((tokens) => {
      // eslint-disable-next-line no-param-reassign
      tokens[currentPage + 1] =
        getWorkflowsResponse?.nextPageToken ?? undefined;
      return tokens;
    });
  }, [currentPage, getWorkflowsResponse?.nextPageToken]);

  React.useEffect(() => {
    startPolling(POLL_INTERVAL);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const fetchData = async ({ filters, pageIndex, sortBy }: FetchDataInput) => {
    let pageToken = pageTokens[pageIndex] ?? '';
    let pageNumber = pageIndex;
    const sort = sortBy[0];
    if (sort?.id) {
      const sortByValue = sort.id as AccountingRunSortByOptions;
      const direction = sort.desc ? SortDirection.Desc : SortDirection.Asc;
      if (sortByValue !== sorting?.sortBy || direction !== sorting?.direction) {
        setSorting({
          sortBy: sortByValue,
          direction,
        });

        // Sorting has changed so we reset the page to the first page
        pageToken = '';
        pageNumber = 0;
      }
    } else {
      setSorting(undefined);
    }

    setTableFilters(filters);
    setCurrentPage(pageNumber);
    setNextPageToken(pageToken);

    refetch();
  };

  return (
    <Table
      columns={columns}
      data={getWorkflowsResponse?.data ?? []}
      filterKind="Abrechnungsläufe"
      isLoading={loading}
      rowLink={rowLink}
      compact
      withAlternatingRows
      withPagination
      withBoxShadow
      withFilter
      withBooleanFilter={booleanFilters}
      pageSize={pageSize}
      pageNumber={currentPage}
      total={getWorkflowsResponse?.totalCount}
      fetchData={fetchData}
    />
  );
}
