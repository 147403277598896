/** @module env
 * One of the issues with baking configuration into static build artifacts is,
 * that we had to build a new (and in that way also distinct) artifact for each
 * target environment.
 * Each build could potentially produce slightly different results, even when
 * building from the same underlying source.
 *
 * To make operating our components predictable and testable, we want to provide
 * an easy and reliable way to deliver and configure a single build artifact
 * for multiple environments.
 *
 * This allows us to build a single artifact, test the same artifact with
 * different configurations (e.g. on different environments), which only have
 * to follow a simple configuration contract.
 *
 * This then also aligns with following sections of 12-factor recommendations:
 *
 *  * §1: codebase
 *  * §3: config
 *  * §5: build, release, run
 *  * §10: dev/prod parity
 *
 * To accomodate for both (local) development tooling, which usually builds
 * upon hot-reload-capable dev-servers and other nice features, as well as
 * production-like operations, `_ae_env_` is introduced.
 *
 * This works around webpack plugins that bake certain -- seemingly -- static
 * things, like `process.env` into the build artifact.
 * See also <https://webpack.js.org/plugins/environment-plugin/>
 *
 * @see 12-factor guidelines (aged, but still valid): <https://12factor.net/>
 */

interface FrontendEnv {
  REACT_APP_BACKEND_BASE_URI: string;
  REACT_APP_GRAPHSERVER_WS_URI: string;
  REACT_APP_DOCUMENT_MANAGER_URI?: string;
  REACT_APP_MS_CLARITY_TRACKING?: string;
}

// With the development server, we don't expect `window._env_` to be set, so
// it's derived from `process.env` here.
/**
 * _ae_env_ is our single reference point for frontend configuration matters.
 * Its contents may either be provided via window._ae_env_, or from process.env.
 *
 * Static builds
 * -------------
 * `_ae_env_` is derived from `window._ae_env_`.
 * The latter may be provided as a simple `Map<string, string>` in `config.js`
 * of the build artifact. This script is loaded from `index.html`.
 *
 * Behaviour is currently undefined for anything else than null and plain
 * string-mappings.
 *
 * Dev server
 * ----------
 * When `window._ae_env_` is not specified or null, a fallback to `process.env`
 * is provided. This is usually not useful, when e.g. serving a client, though.
 *
 * If `process.env` is used here, its contents are copied into a new object,
 * which is then assigned to `_ae_env_`.
 */
const _ae_env_: FrontendEnv =
  // Try `window._ae_env_` first.
  // The convoluted double-cast is done to a) avoid issues with `window`,
  // and b) to document the expected `window._ae_env_` property.
  (window as unknown as { [key: string]: any; _ae_env_: FrontendEnv })
    ?._ae_env_ || // the 2nd option is to simply use `process.env`. This might leak details
  // about the underlying environment, thus should only be used in development
  // settings. It is not relevant when using static builds, since browsers and
  // not concern us then anyways.
  // To avoid changes to `process.env` when working with `_ae_env_`, a new
  // object is created, instead of simply referencing the origin.
  { ...process.env };

if (!_ae_env_.REACT_APP_GRAPHSERVER_WS_URI) {
  _ae_env_.REACT_APP_GRAPHSERVER_WS_URI = 'wss:'; // safe default: websocket over SSL on the same host
}

const isTest = process.env.NODE_ENV === 'test';
const isDevelopment = process.env.NODE_ENV === 'development';

const host = process.env.REACT_APP_TEST_HOST;
const username = process.env.REACT_APP_TEST_USERNAME
  ? process.env.REACT_APP_TEST_USERNAME
  : '';
const password = process.env.REACT_APP_TEST_PASSWORD
  ? process.env.REACT_APP_TEST_PASSWORD
  : '';

export { isTest, isDevelopment, host, username, password, _ae_env_ };
