import {
  AlertRetryable,
  Header,
  Icons,
  List,
  ListItem,
  Main,
} from '@ampeersenergy/ampeers-ui-components';
import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { useHistory } from 'react-router-dom';

import {
  useGetAllScheduleRequestsResultsQuery,
  useGetAllScheduleRequestsResultsWfmQuery,
} from '../../graphql-types';
import { DocTitle } from '../docTitle';

import {
  getIconColor,
  getJobIcon,
  translateJobName,
} from './taskManagerHelper';

export function TasksStatus() {
  const history = useHistory();
  const { data, error, loading, refetch } =
    useGetAllScheduleRequestsResultsQuery();

  const {
    data: dataWFM,
    error: errorWFM,
    loading: loadingWFM,
    refetch: refetchWFM,
  } = useGetAllScheduleRequestsResultsWfmQuery();

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  React.useEffect(() => {
    refetchWFM();
  }, [refetchWFM]);

  const jobs = React.useMemo(() => {
    if (
      data?.getAllScheduleRequestsResults?.__typename ===
        'MultipleScheduleRequestsSuccess' &&
      dataWFM?.getAllScheduleRequestsResultsWFM?.__typename ===
        'MultipleScheduleRequestsSuccess'
    ) {
      const addedDataSourceDataAsyncJobService =
        data.getAllScheduleRequestsResults.results.map((result) => {
          const dataSource = 'AJS';
          return { ...result, dataSource };
        });
      const addedDataSourceDataWFM =
        dataWFM.getAllScheduleRequestsResultsWFM.results.map((result) => {
          const dataSource = 'WFM';
          return { ...result, dataSource };
        });
      return [addedDataSourceDataAsyncJobService, addedDataSourceDataWFM]
        .flat()
        .sort(
          (a, b) =>
            new Date(b.startedOn!).getTime() - new Date(a.startedOn!).getTime(),
        );
    }
  }, [data, dataWFM]);

  const errorHandler = React.useCallback(() => {
    if (error || errorWFM) {
      return <AlertRetryable error={error} />;
    }
    if (
      data?.getAllScheduleRequestsResults?.__typename === 'JobNotFound' ||
      dataWFM?.getAllScheduleRequestsResultsWFM?.__typename === 'JobNotFound'
    ) {
      return <AlertRetryable message="Job nicht gefunden" />;
    }
    if (
      data?.getAllScheduleRequestsResults?.__typename ===
        'ScheduleRequestsError' ||
      dataWFM?.getAllScheduleRequestsResultsWFM?.__typename ===
        'ScheduleRequestsError'
    ) {
      return (
        <AlertRetryable message="Beim Laden des Jobs ist ein Fehler aufgetreten" />
      );
    }
    return null;
  }, [data, dataWFM, error, errorWFM]);

  return (
    <>
      <DocTitle titleParts={['Taskmanager']} />
      <Main>
        <Header Icon={Icons.Time} hideBackground>
          Taskmanager
        </Header>
        {errorHandler()}
        <List header="Tasks">
          {jobs &&
            jobs?.map((result) => {
              const hasOperationError = result?.result?.some(
                (res) => res.data.__typename === 'OperationError',
              );

              const color = getIconColor(result.status, hasOperationError);
              const jobName = translateJobName(result.jobName);

              return (
                <ListItem
                  Icon={getJobIcon(result.status, hasOperationError)}
                  iconProps={{
                    color,
                    size: 30,
                  }}
                  timestamp={
                    result.startedOn ? new Date(result.startedOn) : undefined
                  }
                  title={result.jobLabel ?? jobName}
                  onClick={() =>
                    history.push(`/tasks/${result.dataSource}/${result.jobId}`)
                  }
                  key={result.jobId}
                >
                  {result.progress}/{result.total} {jobName} verarbeitet
                </ListItem>
              );
            })}
          {loading || loadingWFM ? <LoadingItems /> : null}
        </List>
      </Main>
    </>
  );
}

function LoadingItems() {
  return (
    <ContentLoader width="100%" height="260">
      <circle cx="30" cy="20" r="12" />
      <rect x="60" y="0" width="200" height="20" />
      <rect x="60" y="24" width="40" height="16" />

      <circle cx="30" cy="90" r="12" />
      <rect x="60" y="70" width="220" height="20" />
      <rect x="60" y="94" width="40" height="16" />

      <circle cx="30" cy="160" r="12" />
      <rect x="60" y="140" width="180" height="20" />
      <rect x="60" y="164" width="40" height="16" />

      <circle cx="30" cy="230" r="12" />
      <rect x="60" y="210" width="240" height="20" />
      <rect x="60" y="234" width="40" height="16" />
    </ContentLoader>
  );
}
