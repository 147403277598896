import gql from 'graphql-tag';

export default gql`
  mutation createMsconsReadingsBatched($payload: JSONObject!) {
    createMsconsReadingsBatched(payload: $payload) {
      succeeded {
        id
        meterNumber
        value
        date
        fileName
      }
      failed {
        id
        meterNumber
        error
        value
        date
        fileName
      }
    }
  }
`;
