import * as React from 'react';
import styled from 'styled-components/macro';
import { Button, FlexRow, Modal } from '@ampeersenergy/ampeers-ui-components';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';

import {
  AccountingType,
  InvoiceCycle,
  useCreateAccountingRunWorkflowMutation,
  WorkflowType,
} from '../../../graphql-types';
import { ContractNameLabel } from '../../../components/contractNameLabel';
import {
  getClosestDatetoEndDate,
  getLastSettlementDate,
  transformInvoiceCycle,
} from '../create/helpers';

import { ClosingAccountingInvoice } from './dashboardAccountingTypes';

const Wrapper = styled.div`
  padding: 12px 0;
  overflow: scroll;
  max-height: 80vh;
`;

const ContractRow = styled(FlexRow)`
  padding: 16px 0px 16px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid ${(props) => props.theme.palette.border};
  &:last-child {
    border-bottom: 0px;
  }
`;

export interface CreateAccountingWorkflow {
  name: string;
  contractId: string;
  accountingType: AccountingType;
  settlementDay?: string;
  settlementMonth?: string;
  startAt?: string;
  endAt?: string;
}

export type StartAccountingWorkflow = React.Dispatch<
  React.SetStateAction<CreateAccountingWorkflow | null>
>;

interface AccountingContractModalProps {
  invoices: ClosingAccountingInvoice;
  title: string;
  open: boolean;
  onClose: () => void;
}

const getStartPaymentPeriod = ({
  contractEndDatetime,
  contractStartDatetime,
  settlementDay,
  settlementMonth,
  invoiceCycle,
}: {
  contractEndDatetime: DateTime;
  contractStartDatetime: DateTime;
  settlementDay: number;
  settlementMonth: number;
  invoiceCycle?: InvoiceCycle | null;
}) => {
  const settlementDatetime =
    getLastSettlementDate(
      contractEndDatetime,
      invoiceCycle,
      settlementMonth,
      settlementDay,
    ) || contractStartDatetime;

  return getClosestDatetoEndDate(contractEndDatetime, [
    contractStartDatetime,
    settlementDatetime.plus({ day: 1 }),
  ]);
};

export function AccountingContractModal({
  invoices,
  open,
  title,
  onClose,
}: AccountingContractModalProps) {
  const [createAccountingRunWorkflowMutation] =
    useCreateAccountingRunWorkflowMutation();
  const history = useHistory();

  const handleCreateAccountingWorkflow = async (
    invoice: ClosingAccountingInvoice[0],
  ) => {
    if (invoice) {
      const { startDate, endDate, settlement, id, label, customerName } =
        invoice;
      const contractEndDatetime = DateTime.fromISO(endDate, {
        zone: 'utc',
      });
      const contractStartDatetime = DateTime.fromISO(startDate, {
        zone: 'utc',
      });

      const invoiceCycle = transformInvoiceCycle(invoice.invoiceCycle);
      const startPaymentPeriod =
        getStartPaymentPeriod({
          contractEndDatetime,
          contractStartDatetime,
          settlementDay: +settlement.day,
          settlementMonth: +settlement.month,
          invoiceCycle,
        }) || contractStartDatetime;
      const { day, month } = settlement;
      const { data } = await createAccountingRunWorkflowMutation({
        variables: {
          payload: {
            accountingType: AccountingType.ClosingAccountingMove,
            name: `${label}_Endabrechnung ${customerName}`,
            paymentPeriodStartAt: startPaymentPeriod.toISODate(),
            paymentPeriodEndAt: contractEndDatetime.toISODate(),
            contractToAccount: id,
            settlementDay: day ? +day : 31,
            settlementMonth: month ? +month : 12,
            workflowType: WorkflowType.Single,
            invoiceCycle,
          },
        },
      });

      const workflow = data?.createAccountingRunWorkflow;
      history.push(`/accounting/runs/${workflow?.id}`);
    }
  };
  return (
    <Modal
      isOpen={open}
      title={title}
      onRequestClose={onClose}
      contentLabel="accounting-invoice-modal"
      minWidth={600}
    >
      <Wrapper>
        {invoices.map((invoice, i) => (
          <ContractRow key={`${i}-${invoice.id}-${invoice?.customerLabel}`}>
            <ContractNameLabel
              contractName={`${invoice.customerName}`}
              contractLabel={invoice.label}
            />
            <Button
              secondary
              small
              onClick={() => handleCreateAccountingWorkflow(invoice)}
              disabled={new Date(invoice.endDate) > new Date()}
            >
              Abrechnen
            </Button>
          </ContractRow>
        ))}
      </Wrapper>
    </Modal>
  );
}
