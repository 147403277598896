import {
  AlertInfo as InfoMsg,
  AlertRetryable,
  Bold,
  Button,
  ErrorBox,
  FlexRow,
  Input,
  Label,
  Link,
  Modal,
  Overlay,
  Select,
  SpinnerDark,
  Tab,
  Table,
  Tabs,
} from '@ampeersenergy/ampeers-ui-components';
import { Result } from 'neverthrow';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import * as yup from 'yup';

import { PrepareContractsUpdate } from './preparecontractsupdate/PrepareContractsUpdate';

const importerSupportLink =
  'https://support.ls.ampeers.energy/support/solutions/articles/80000965020-kunden-wechselliste';

export type Row = {
  values: string[];
};

export type FailedRow = {
  error: string;
  values: string[];
};

export interface ImportResult {
  headers?: string[];
  succeeded?: Row[];
  failed?: FailedRow[];
  backgroundTask?: boolean;
  warningMessage?: React.ReactNode; //  Warning message to be displayed on top of the table
}

export interface PrepareImportResult<T = any> {
  valid: T[];
  failed: FailedRow[];
}

export interface ImportError {
  title: string;
  message: string;
}

type ResultImportResult = Result<ImportResult, ImportError>;

type ImportHandlerResult = ResultImportResult | PrepareImportResult;

export type ImportHandler = ({
  type,
  file,
  updateProgress,
  error,
  fileName,
}: {
  type: ImportType;
  file?: string;
  updateProgress: (progress: number) => void;
  error?: Error | string;
  fileName?: string; // Filename to download the csv file
}) => Promise<ImportHandlerResult>;

export interface ImportType {
  /**
   * Localized label for that type.
   * */
  label: string;

  /**
   * Technical id used to identify that type.
   * */
  id: string;

  /**
   * Url to download an empty csv template
   * */
  templateURL?: string;

  /**
   * File mimeTypes we should accept
   * for that Type
   * */
  mimeTypes: string[];

  /**
   * Wether we want display a progress indicator or
   * if that value is true a indeterminate spinner. If that is
   * set to true calls to `updateProgress` have no effect.
   * */
  indeterminateProgress?: boolean;

  /**
   * Accepted file extensions
   */
  acceptedExtensions?: string[];

  /**
   * Instruction type to be displayed when uploading the file
   */
  importTypeInstructions?: 'csv' | 'mscons' | undefined;
}

/**
 * If the import Flow is opened from '/accoutning/import, we need to change the design and logic, for it we use this importViewType
 * The changes respect the normal ImportViewType are the use of <Modal /> instead of <Overlay />,
 * In the bookings there is also a required fileName besides the file import
 */
export type ImportViewType = 'default' | 'bookings';
export interface ImportFlowProps {
  types: ImportType[];

  /**
   * Gets called after the user selected a file.
   * */
  onImport: ImportHandler;

  /**
   * Gets called when the user requests to download/export the results.
   * */
  onImportOverviewDownload(
    result: Result<ImportResult, ImportError>,
    type: ImportType,
  ): void;

  onClose(): void;

  /**
   * default Type to preselect.
   * */
  defaultType?: ImportType['id'];

  /**
   * Type of the modal, the design changes when displayed on one place or another;
   */
  importViewType?: ImportViewType;
}

/**
 * validates if the extension of an imported file is valid according
 * to the accepted extesions for that file type
 * @param files (File | string)[]
 * @param acceptedExtensions string[] | undefined
 * @returns string[]
 */
const validateFileExtension = (
  files: (File | string | undefined)[],
  acceptedExtensions?: string[],
) => {
  if (!acceptedExtensions) return [];
  return files
    .map((file) => {
      if (!file) return file;
      const fileName = file instanceof File ? file.name : file;

      const splitFileName = fileName.split('.');
      const extension = splitFileName[splitFileName.length - 1];
      const isValid = acceptedExtensions.includes(extension);

      return isValid ? false : fileName;
    })
    .filter((v: any) => v);
};

function isResultType(type: ImportHandlerResult): type is ResultImportResult {
  return !Object.prototype.hasOwnProperty.call(type, 'valid');
}

export function ImportFlow({
  types,
  onImport,
  onClose,
  onImportOverviewDownload,
  defaultType,
  importViewType = 'default',
}: ImportFlowProps) {
  const [step, setStep] = useState<
    'import' | 'processing' | 'prepare' | 'done'
  >('import');
  const [importError, setImportError] = useState<ImportError | undefined>();
  const [progress, setProgress] = useState<number | null>(null);
  const [processingDone, setProcessingDone] = useState(false);
  const [processResult, setProcessResult] = useState<ResultImportResult | null>(
    null,
  );
  const [prepareResult, setPrepareResult] =
    useState<PrepareImportResult | null>(null);
  const [selectedType, setSelectedType] = useState<ImportType | undefined>(
    defaultType ? types.find((t) => t.id === defaultType) : undefined,
  );

  const resetImport = useCallback(() => {
    setStep('import');
    setProgress(null);
    setProcessingDone(false);
    setProcessResult(null);
  }, [setStep, setProgress, setProcessingDone, setProcessResult]);

  const onFile = useCallback<
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent,
      fileName?: string,
    ) => void
  >(
    (acceptedFiles, fileRejections, event, fileName) => {
      const doProcessing = async () => {
        setStep('processing');
        const file = acceptedFiles[0];
        let error;

        if (fileRejections.length) {
          error = fileRejections.map((fr) => fr.errors[0].message).join('\n');
        }

        const rawFileContent = await file?.text();

        const result = await onImport({
          type: selectedType!,
          file: error ? undefined : removeCSVEmptyLines(rawFileContent),
          updateProgress: setProgress,
          error,
          fileName,
        });
        setProcessingDone(true);

        if (isResultType(result)) {
          if (selectedType!.id === 'contracts_update' && result.isErr()) {
            setImportError(result.error);
          } else if (
            result.isOk() &&
            result.value.backgroundTask &&
            !result.value.failed
          ) {
            onClose();
          } else {
            setProcessResult(result);
          }
        } else {
          setPrepareResult(result);
        }
      };
      doProcessing();
    },
    [onImport, selectedType, onClose],
  );

  /**
   * the steps triggers the transition on its own
   * after all animations are done
   */
  const onDoneStep = useCallback(() => {
    if (selectedType?.id === 'contracts_update') {
      setStep('prepare');
    } else {
      setStep('done');
    }
  }, [selectedType]);

  const content = (
    <>
      {selectedType && step !== 'import' && (
        <SubTitle>{selectedType.label} </SubTitle>
      )}
      {importError ? (
        <AlertRetryable
          title={importError.title}
          message={importError.message}
        />
      ) : null}
      {step === 'import' && (
        <ImportStep
          types={types}
          onFile={onFile}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          importViewType={importViewType}
        />
      )}
      {step === 'processing' && (
        <ProcessingStep
          progress={progress}
          isDone={processingDone}
          onDoneStep={onDoneStep}
          indeterminateProgress={selectedType?.indeterminateProgress}
        />
      )}
      {step === 'prepare' && (
        <PrepareStep
          type={selectedType!}
          result={prepareResult}
          resetImport={resetImport}
          onClose={onClose}
        />
      )}
      {step === 'done' && (
        <ResultStep
          result={processResult!}
          type={selectedType!}
          onImportOverviewDownload={onImportOverviewDownload}
          resetImport={resetImport}
        />
      )}
    </>
  );

  return (
    <>
      {importViewType === 'bookings' && (
        <Modal
          title="Import"
          isOpen
          onRequestClose={onClose}
          contentLabel="booking-Modal"
          minWidth={1000}
          minHeight={700}
        >
          {content}
        </Modal>
      )}
      {importViewType === 'default' && (
        <Overlay title="Import" isOpen onRequestClose={onClose}>
          {content}
        </Overlay>
      )}
    </>
  );
}

interface PrepareStepProps {
  type: ImportType;
  result: PrepareImportResult | null;
  resetImport: () => void;
  onClose: () => void;
}

function PrepareStep({ type, result, resetImport, onClose }: PrepareStepProps) {
  if (!result) return null;

  if (type.id === 'contracts_update') {
    return (
      <PrepareContractsUpdate
        result={result}
        resetImport={resetImport}
        onClose={onClose}
      />
    );
  }
  return <></>;
}

function ImportStep({
  types,
  onFile,
  selectedType,
  setSelectedType,
  importViewType,
}: {
  types: ImportType[];
  onFile: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent,
    fileName?: string,
  ) => void;
  selectedType: ImportType | undefined;
  setSelectedType: (t: ImportType) => void;
  importViewType?: ImportViewType;
}) {
  const [fileName, setFileName] = useState<string>('');
  const [fileNameError, setFileNameError] = useState<string>('');

  const schema = yup.object().shape({
    fileName: yup.string().required('Pflichtfeld'),
  });

  const handleOnFile = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => {
    // If the import happens on the /accounting menu, the fileName is required
    if (importViewType === 'bookings') {
      try {
        schema.validateSync({ fileName });

        onFile(acceptedFiles, fileRejections, event, fileName);
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          setFileNameError(err.message);
        } else {
          setFileNameError(
            'There is an error with the name field, please adjust the name',
          );
        }
      }
    } else {
      onFile(acceptedFiles, fileRejections, event);
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleOnFile,
    maxFiles: 1,
    multiple: false,
    accept: selectedType?.mimeTypes.join(', '),
    validator: (file) => {
      const invalidFiles = validateFileExtension(
        [file],
        selectedType!.acceptedExtensions,
      );

      if (invalidFiles.length) {
        return {
          code: 'invalid-extension',
          message: `Ungültiges Format. Datei muss die Endung ${selectedType.acceptedExtensions
            ?.map((s) => `.${s}`)
            .join(', ')} haben.`,
        };
      }

      return null;
    },
  });

  const onTemplateRequest = useCallback(() => {
    if (selectedType?.templateURL)
      window.location.href = selectedType?.templateURL;
  }, [selectedType]);

  return (
    <>
      <Select
        id="import-type"
        label="Was soll importiert werden?"
        value={selectedType?.id || ''}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          const target = types.find((t) => t.id === evt.target.value);
          setSelectedType(target!);
        }}
      >
        <option disabled value="" key="-">
          Bitte wählen
        </option>
        {types.map((t) => (
          <option value={t.id} key={t.id}>
            {t.label}
          </option>
        ))}
      </Select>
      {importViewType === 'bookings' && (
        <>
          <Label> Benennung des Stapels </Label>
          <Input
            id="fileName"
            placeholder="Bitte ausfüllen"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            required
          />
          <ErrorBox> {fileNameError}</ErrorBox>
        </>
      )}
      {selectedType && (
        <Wrapper>
          <DropContainer className={isDragActive ? 'is-dragging' : ''}>
            <div className="inputWrapper" {...getRootProps()}>
              <input {...getInputProps()} />
              Zieh einfach die Datei in dieses Feld oder wähle diese mit Klick
              auf “Datei auswählen“ in Deinem Dateiverzeichnis aus.
              <Button>Datei auswählen</Button>
            </div>
          </DropContainer>
          {selectedType?.importTypeInstructions && (
            <InfoMsgWrapped title="Anleitung">
              {selectedType?.importTypeInstructions === 'mscons' && (
                <>
                  <ol>
                    <li>
                      Für den Import wird nur der MSCONS Anwendungsfall
                      “Zählerstände” (13017) in den Versionen 2.3, 2.3b und 2.3c
                      unterstützt.
                    </li>
                    <li>MSCONS-Nachrichten müssen das .txt Format haben.</li>
                    <li>Dateien müssen einzeln hochgeladen werden.</li>
                  </ol>
                  <br />
                  <p>
                    <b>Hinweis: </b>MSCONS-Nachrichten des Anwendungsfalls
                    “Korrektur” (13006) können nicht importiert werden. Bei
                    Fragen kannst Du Dich an unseren Helpdesk wenden.
                  </p>
                </>
              )}
              {selectedType?.importTypeInstructions === 'csv' && (
                <ol>
                  <li>
                    Aktuelle Vorlage für den &lsquo;{selectedType.label}
                    -Import&rsquo; herunterladen.
                    <div>
                      <Button secondary onClick={onTemplateRequest}>
                        Vorlage
                      </Button>
                    </div>
                  </li>
                  <li>
                    Vorlage mit Daten befüllen. Vorsicht: Die Verwendung von
                    Microsoft Excel zur Bearbeitung führt oft zu
                    Formatierungsfehlern bei Sonderzeichen (z.B. bei Umlauten).
                  </li>
                  <li>
                    Um Formatierungsfehler beim Import zu vermeiden, sollte die
                    CSV-Datei als &quot;CSV UTF-8 (durch Trennzeichen getrennte
                    Datei) (.csv)&quot; gespeichert werden.
                  </li>
                  <li>
                    Vorlage zur Verarbeitung auf der linken Seite hochladen.
                  </li>
                  {selectedType.id === 'bookings' && (
                    <li>
                      Es können maximal 500 Buchungen pro Upload eingespielt
                      werden.
                    </li>
                  )}
                </ol>
              )}
            </InfoMsgWrapped>
          )}
        </Wrapper>
      )}
    </>
  );
}

function ProcessingStep({
  progress,
  isDone,
  onDoneStep,
  indeterminateProgress,
}: {
  progress?: number | null;
  isDone?: boolean;
  onDoneStep?: () => void;
  indeterminateProgress?: boolean;
}) {
  useEffect(() => {
    if (isDone && onDoneStep) onDoneStep();
  }, [isDone, onDoneStep]);

  return (
    <SpinnerWrap>
      <SpinnerDark size={55} />
      {!indeterminateProgress && (
        <SpinnerProgress> {Math.floor(progress || 0)}%</SpinnerProgress>
      )}
      <ProcessMsg>Verarbeiten…</ProcessMsg>
    </SpinnerWrap>
  );
}

const errorColumn = {
  Header: 'Fehler',
  accessor: (row: FailedRow) => {
    return row.error;
  },
  Cell: ({ cell }: any) => {
    return <ErrorColumn>{cell.value}</ErrorColumn>;
  },
};

function ResultStep({
  result,
  type,
  onImportOverviewDownload,
  resetImport,
}: {
  result: Result<ImportResult, ImportError>;
  type: ImportType;
  onImportOverviewDownload(
    result: Result<ImportResult, ImportError>,
    type: ImportType,
  ): void;
  resetImport: () => void;
}) {
  const columns = useMemo(() => {
    if (result.isErr()) return [];

    return (
      result.value.headers?.map((h, index) => ({
        Header: h,
        accessor: (row: Row) => {
          return row.values[index];
        },
      })) ?? []
    );
  }, [result]);

  if (result.isErr()) {
    return (
      <AlertRetryable
        title={result.error.title}
        message={result.error.message}
      />
    );
  }
  const succeededCount = result.value?.succeeded?.length ?? 0;
  const failedCount = result.value?.failed?.length ?? 0;

  const header = (
    <PullRight>
      <Button secondary onClick={() => resetImport()}>
        Neuer Import
      </Button>
      <Button secondary onClick={() => onImportOverviewDownload(result, type)}>
        Download Import Übersicht
      </Button>
    </PullRight>
  );

  if (result.value?.backgroundTask) {
    if (failedCount === 0) {
      return (
        <>
          {header}
          <p>
            <strong>
              {succeededCount > 0
                ? `${succeededCount} Erfolgreich`
                : result.value.warningMessage}
            </strong>
          </p>
        </>
      );
    }
    return (
      <>
        {header}
        <p>
          <strong>{`${failedCount} Fehlerhaft`}</strong>
        </p>
        <p>
          Bitte korrigiere die unten stehenden Einträge, um mit dem Import
          fortzufahren. Weitere Informationen findest Du{' '}
          <Link
            as="a"
            href={importerSupportLink}
            target="_blank"
            rel="noreferrer"
          >
            hier
          </Link>
          .
        </p>
        <Table
          isLoading={false}
          data={result.value.failed ?? []}
          columns={[errorColumn, ...columns]}
        />
      </>
    );
  }

  return (
    <>
      {header}
      {result.value.warningMessage && <div>{result.value.warningMessage}</div>}
      <Tabs>
        <Tab title={`${succeededCount} Erfolgreich`}>
          <Table
            isLoading={false}
            data={result.value.succeeded ?? []}
            columns={columns}
          />
        </Tab>
        <Tab title={`${failedCount} Fehlerhaft`}>
          <Table
            isLoading={false}
            data={result.value.failed ?? []}
            columns={[errorColumn, ...columns]}
          />
        </Tab>
      </Tabs>
    </>
  );
}

const emptyRegex = new RegExp(/^;+$/);
export function removeCSVEmptyLines(fileContent: string): string {
  const lines = fileContent.trim().split(/\s*[\r\n]+\s*/g);

  const noEmptyLines = lines.reduce((acc: string[], line: string) => {
    if (emptyRegex.test(line)) {
      return acc;
    }
    return [...acc, line];
  }, []);

  return noEmptyLines.join('\n');
}

const Wrapper = styled(FlexRow)`
  justify-content: space-between;
  margin-top: 20px;
`;

const DropContainer = styled.section`
  border-radius: 6px;
  border: 2px dashed ${(props) => props.theme.palette.border};

  min-height: 400px;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  text-align: center;
  color: ${(props) => props.theme.palette.textMuted};

  &.is-dragging {
    border-color: ${(props) => props.theme.primaryColor};
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    align-self: stretch;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  button {
    margin-top: 20px;
  }
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.palette.textMuted};
  margin-top: -10px;
  margin-bottom: 10px;
`;

const SpinnerWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
`;

const SpinnerProgress = styled.div`
  position: relative;
  top: -41px;
`;

const InfoMsgWrapped = styled(InfoMsg)`
  flex: 1;
  min-height: 404px;
  margin: 0 0 0 10px;

  li {
    margin: 10px 0;
  }
`;

const ErrorColumn = styled(Bold)`
  color: red;
`;

const PullRight = styled(FlexRow)`
  justify-content: flex-end;
  gap: 10px;
`;

const ProcessMsg = styled.div`
  margin-top: 10px;
`;
