import { ApolloClient } from '@apollo/client';

import { client } from '../apollo-client';
import createMsconsReadingsBatched from '../queries/createMsconsReadingsBatched';

class MSCONSService {
  apolloClient: ApolloClient<any>;

  constructor(apolloClient?: ApolloClient<any>) {
    this.apolloClient = apolloClient ?? client;
  }

  push = (payload: JSON) => {
    return this.apolloClient
      .mutate({
        mutation: createMsconsReadingsBatched,
        variables: {
          payload,
        },
      })
      .then(
        ({ data }) =>
          data[(createMsconsReadingsBatched!.definitions[0] as any).name.value],
      );
  };

  parse = async (files: File[]) => {
    const fileContents = await Promise.all(
      files.map((file: File) => file?.text?.() ?? file),
    );
    return files.reduce(
      (acc: { [key: string]: any }, file: File, index: number) => ({
        ...acc,
        [file.name]: fileContents[index],
      }),
      {},
    );
  };

  uploadFiles = async (files: File[]) => {
    const parsedFiles = await this.parse(files);
    return this.push(parsedFiles as JSON);
  };
}

export default MSCONSService;
