/* eslint-disable guard-for-in, no-restricted-syntax, no-console */
import { DateTime, DateTimeFormatOptions, LocaleOptions } from 'luxon';

import {
  Tariff,
  Meter,
  Person,
  Plant,
  LoadProfile,
  Project,
  BalancingAreaAccount,
  BalancingAreaGroupAccount,
  Supplier,
  MeteringPointOperator,
  Contract,
  Customer,
  Workspace,
  NoticePeriod,
  BdewCode,
} from '../graphql-types';

import numeral from './numeral';
import { EMG_END_DATE } from './emgEndDate';

export const LOADING_STR = 'Lade…';
export const EMPTY_STR = '--';

export function maybeStr(t: string | number | null | undefined): string {
  if (t === undefined || t === null || t === '') return EMPTY_STR;
  return String(t);
}

export function formatString(str?: string | null | number): string {
  return maybeStr(str);
}

export function formatNumber(value: number) {
  return new Intl.NumberFormat('de-DE').format(value);
}

export const shortenString = (value: string, length: number) => {
  if (value.length <= length || length < 10) {
    return value;
  }
  const overflow = value.length - length;
  const startIndex = value.length / 2 - overflow / 2;
  const endIndex = value.length / 2 + overflow / 2;
  return `${value.substring(0, startIndex)} ... ${value.substring(
    endIndex,
    value.length + 1,
  )}`;
};

export function pickName(data?: { name: string }): string {
  if (!data) {
    return 'Fehler: Customer missing';
  }
  const { name } = data;
  if (!name) {
    return 'Fehler: Customer.Person missing';
  }

  return name;
}

export function formatCustomer(c?: { person: Pick<Person, 'name'> }) {
  if (!c) {
    return 'Fehler: Customer missing';
  }

  const { person } = c;

  if (!person) {
    return 'Fehler: Customer.Person missing';
  }

  return person.name;
}

export function formatContract(c?: Pick<Contract, 'customer'>) {
  if (!c) {
    return 'Fehler: Contract missing';
  }

  return formatCustomer(c.customer);
}

export function formatSuggestedCustomer(c?: {
  person: Pick<Person, 'name'>;
  label: Pick<Customer, 'label'>;
}) {
  if (!c) return EMPTY_STR;
  return `${c.person.name} / ${c.label}`;
}

enum Kind {
  'nicht definiert',
  'Mieterstrom',
  'PV-Strom',
  'BHKW-Strom',
  'Hausanschluss',
  'Ladesäule',
  'Wärmepumpe',
  'Allgemeinstrom',
}

export function formatTariffKind(t?: Pick<Tariff, 'kind'> | null) {
  if (!t) return EMPTY_STR;

  return Kind[parseInt(t.kind, 10)];
}

export function formatTariff(t?: Pick<Tariff, 'nameInternal'> | null) {
  if (!t) return EMPTY_STR;
  return t.nameInternal;
}

const period: any = {
  months: 'Monate',
  weeks: 'Wochen',
  days: 'Tage',
};

export const noticePeriodTo: any = {
  ml: 'zum Monatsende',
  vl: 'zum Vertragsende',
};

export function formatTariffNoticePeriod(t?: NoticePeriod | null) {
  if (!t) return EMPTY_STR;
  if (t.count === 0) {
    return 'Keine';
  }
  return `${t.count} ${period[t.period]} ${noticePeriodTo[t.to]}`;
}

export function formateMeasurementConceptText(
  measurementConceptString?: string,
): string {
  if (!measurementConceptString) {
    return '';
  }

  return measurementConceptString.replace(/_/g, '-');
}

export const priceGuaranteeReference: any = {
  v: 'Vertragsunterzeichnung',
  l: 'Lieferbeginn',
};

export function formatPriceGuaranteeReference(t?: Tariff): string {
  if (!t) return EMPTY_STR;
  return priceGuaranteeReference[t.priceGuaranteeReference];
}

export function formatTariffPriceSheet(
  t?: Pick<Tariff, 'nameInternal' | 'priceSheets'> | null,
) {
  if (!t) return EMPTY_STR;
  return `${t.nameInternal} - ${t.priceSheets[0].name}`;
}

const timeFrameGrammaticalNumber = {
  day: { singular: 'Tag', plural: 'Tage' },
  days: { singular: 'Tag', plural: 'Tage' },
  week: { singular: 'Woche', plural: 'Wochen' },
  weeks: { singular: 'Woche', plural: 'Wochen' },
  month: { singular: 'Monat', plural: 'Monate' },
  months: { singular: 'Monat', plural: 'Monate' },
  year: { singular: 'Jahr', plural: 'Jahre' },
  years: { singular: 'Jahr', plural: 'Jahre' },
};

export function formatPeriod(
  value: string | number,
  timeFrame?:
    | 'day'
    | 'days'
    | 'week'
    | 'weeks'
    | 'month'
    | 'months'
    | 'year'
    | 'years',
): string {
  if (!timeFrame) return value === '0' || value === 0 ? 'Keine' : `${value}`;
  switch (value) {
    case 0:
    case '0':
    case undefined:
    case '':
      return 'Keine';
    case 1:
    case '1':
      return `${value} ${timeFrameGrammaticalNumber[timeFrame].singular}`;
    default:
      return `${value} ${timeFrameGrammaticalNumber[timeFrame].plural}`;
  }
}

export function formatMeter(m?: Pick<Meter, 'meterNumber'> | null) {
  if (!m) return EMPTY_STR;
  return m.meterNumber;
}

const meterType = {
  'mso-w': 'MSO-W - Wohnung',
  'mso-a': 'MSO-A - Allgemeinstrom',
  'mso-l': 'MSO-L - Ladesäule',
  'mso-p': 'MSO-P - Wärmepumpe',
  'mso-e': 'MSO-E - Erzeugung',
  'mso-k': 'MSO-K - Kaskade',
  'mso-h': 'MSO-H - Hausanschluss',
};

export function formatMeterType(m?: Pick<Meter, 'meterType'> | null) {
  type MeterTypeType = 'mso-w' | 'mso-a' | 'mso-l' | 'mso-p';
  if (!m) return EMPTY_STR;

  return meterType[m?.meterType.toLowerCase() as MeterTypeType];
}

const currencyFormat = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

const decimalFormat = new Intl.NumberFormat('de-DE', {
  minimumFractionDigits: 2,
});

export function formatCurrency(str?: number): string {
  if (!str) return EMPTY_STR;
  return currencyFormat.format(str);
}

export function formatDecimal(str?: number): string {
  if (!str) return EMPTY_STR;
  return decimalFormat.format(str);
}

const dateTimeFormat: LocaleOptions & DateTimeFormatOptions = {
  ...DateTime.DATE_SHORT,
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  numberingSystem: 'latn',
};

const dateFormat: LocaleOptions & DateTimeFormatOptions = {
  ...DateTime.DATE_SHORT,
  month: '2-digit',
  day: '2-digit',
  numberingSystem: 'latn',
};

interface FormatDateOptions {
  utc?: boolean;
}

export function formatDate(str?: string, options?: FormatDateOptions): string {
  // WARNING: space chars here are THE EMPTY CHAR (U+200C)
  // COPY HERE: "‌‌ "
  if (!str) return `‌‌ ‌‌ ${EMPTY_STR}‌‌ ‌‌ `;

  const date = DateTime.fromISO(str);
  if (options?.utc) {
    return date.toUTC().toLocaleString(dateFormat);
  }
  return date.toLocaleString(dateFormat);
}

export function formatDateTime(str?: string): string {
  if (!str) return EMPTY_STR;

  return DateTime.fromISO(str).toLocaleString(dateTimeFormat);
}

export function formatPlant(p?: Pick<Plant, 'name'> | null) {
  if (!p) return EMPTY_STR;
  return p.name;
}

export function formatBoolean(val?: boolean): string {
  if (val === null) return EMPTY_STR;

  return val === true ? 'Ja' : 'Nein';
}

export function formatLoadProfiles(p?: Pick<LoadProfile, 'name'> | null) {
  if (!p) return EMPTY_STR;
  return p.name;
}

export function formatMeterReadingValue(
  value: number,
  withUnit: boolean = true,
) {
  if (value === null) return EMPTY_STR;
  return `${numeral(value).format('0,0.00')}${withUnit ? ' kWh' : ''}`;
}

export function formatCommunication(value: string) {
  // remove "<p>content</p>" to "content"
  return value.slice(3, -4);
}

export function formatEmgEndDates(
  date?: string,
  showOpenEndText = true,
  showFinishedText = true,
) {
  if (!date) {
    return `‌‌ ‌‌ ${EMPTY_STR}‌‌ ‌‌ `;
  }
  const fixEndDate = DateTime.fromISO(date)
    .setZone('utc')
    .startOf('day')
    .toISO();
  if (
    new Date(fixEndDate).getTime() === EMG_END_DATE.getTime() &&
    showOpenEndText
  ) {
    return 'Offenes Ende';
  }

  if (new Date(fixEndDate).getTime() <= Date.now() && showFinishedText) {
    return 'Beendet';
  }

  return formatDate(fixEndDate);
}

export type ContractStatus =
  | 'LS_INTRANSFER'
  | 'LS_ACTIVE'
  | 'LS_INCANCELATION'
  | 'LS_ENDED'
  | 'LS_REVERSAL'
  | 'FS_INTRANSFER'
  | 'FS_ACTIVE'
  | 'FS_INCANCELATION'
  | 'FS_ENDED'
  | 'FS_REVERSAL';

const contractStatusShortVersionMap: Record<string, string> = {
  LS_INTRANSFER: 'transfer',
  LS_ACTIVE: 'aktiv',
  LS_INCANCELATION: 'gekündigt',
  LS_ENDED: 'beendet',
  LS_REVERSAL: 'storniert',
  FS_INTRANSFER: 'transfer',
  FS_ACTIVE: 'aktiv',
  FS_INCANCELATION: 'gekündigt',
  FS_ENDED: 'beendet',
  FS_REVERSAL: 'storniert',
};

export const contractStatusMap: Record<
  ContractStatus,
  { title: string; description: string }
> = {
  LS_INTRANSFER: {
    title: 'In Transfer (Mieterstrom)',
    description: 'Der Liefer- bzw. Vertragsbeginn steht noch nicht fest.',
  },
  LS_ACTIVE: {
    title: 'In Belieferung (Mieterstrom)',
    description:
      'Das Datum des Liefer- bzw. Vertragsbeginns steht endgültig fest oder der Vertrag wird bereits beliefert.',
  },
  LS_INCANCELATION: {
    title: 'In Kündigung (Mieterstrom)',
    description:
      'Der Vertrag ist gekündigt. Das Kündigungsdatum liegt in der Zukunft.',
  },
  LS_ENDED: {
    title: 'Beendet (Mieterstrom)',
    description:
      'Der Vertrag ist gekündigt. Das Kündigungsdatum liegt in der Vergangenheit.',
  },
  LS_REVERSAL: {
    title: 'Storniert (Mieterstrom)',
    description: '',
  },
  FS_INTRANSFER: {
    title: 'In Transfer (Vollversorgung)',
    description: 'Der Liefer- bzw. Vertragsbeginn steht noch nicht fest.',
  },
  FS_ACTIVE: {
    title: 'In Belieferung (Vollversorgung)',
    description:
      'Das Datum des Liefer- bzw. Vertragsbeginns steht endgültig fest oder der Vertrag wird bereits beliefert.',
  },
  FS_INCANCELATION: {
    title: 'In Kündigung (Vollversorgung)',
    description:
      'Der Vertrag ist gekündigt. Das Kündigungsdatum liegt in der Zukunft.',
  },
  FS_ENDED: {
    title: 'Beendet (Vollversorgung)',
    description:
      'Der Vertrag ist gekündigt. Das Kündigungsdatum liegt in der Vergangenheit.',
  },
  FS_REVERSAL: {
    title: 'Storniert (Vollversorgung)',
    description: '',
  },
};

export const shortFormatContractStatus = (status?: string | null): string => {
  if (status === null || status === undefined) return 'nicht zugeordnet';
  return contractStatusShortVersionMap[status.toUpperCase()];
};

export const formatContractStatus = (
  status?: string | null,
  showMieterstrom?: boolean | null,
): { title: string; description: string } => {
  if (!status) return { title: 'nicht zugeordnet', description: '' };

  if (!showMieterstrom) {
    const { title, description } = contractStatusMap[status as ContractStatus];

    return { title: title.replace('(Mieterstrom)', ''), description };
  }

  return contractStatusMap[status as ContractStatus];
};

export function formatProject(p?: Pick<Project, 'name'> | null) {
  if (!p) return EMPTY_STR;
  return p.name;
}

export function formatBalancingAreaAccount(
  p?: Pick<BalancingAreaAccount, 'name' | 'eic'> | null,
) {
  if (!p) return EMPTY_STR;
  return `${p.name} / ${p.eic}`;
}

export function formatBalancingAreaGroupAccount(
  p?: Pick<BalancingAreaGroupAccount, 'name' | 'eic'> | null,
) {
  if (!p) return EMPTY_STR;
  return `${p.name} / ${p.eic}`;
}

export function formatSupplier(p?: Pick<Supplier, 'name' | 'code'> | null) {
  if (!p) return EMPTY_STR;
  return `${p.name} / ${formatString(p.code)}`;
}

export function formatMeteringPointOperator(
  p?: Pick<MeteringPointOperator, 'code' | 'name'> | null,
) {
  if (!p) return EMPTY_STR;
  return `${p.name} / ${p.code}`;
}

export function formatTwoDigitString(str?: string): string {
  if (!str) return `‌‌${EMPTY_STR}‌‌`;

  return `0${str}`.slice(-2);
}

const accountingTypes: { [key: string]: string } = {
  standardAccounting: 'Turnusverbrauchsabrechnung',
  closingAccountingMove: 'Schlussrechnung Auszug',
  closingAccountingThird: 'Schlussrechnung Wechsel',
  betweenAccounting: 'Zwischenabrechnung',
  correctionAccounting: 'Korrekturrechnung',
};

export const formatAccountingType = (type?: string): string => {
  if (!type || !Object.prototype.hasOwnProperty.call(accountingTypes, type))
    return 'Unbekannter Abrechnungstyp';
  return accountingTypes[type];
};

export function formatWorkspace(w?: Pick<Workspace, 'name'> | null) {
  if (!w) return EMPTY_STR;
  return w.name;
}

export function formatPowerSupplier(code?: BdewCode) {
  if (!code || !code.CompanyName) return EMPTY_STR;
  return code.CompanyName;
}

const dateFormatDayAndMonth: LocaleOptions & DateTimeFormatOptions = {
  month: '2-digit',
  day: '2-digit',
};

export const formatSettlementDay = (day: number, month: number) => {
  const date = new Date(9999, month - 1, day);
  return DateTime.fromJSDate(date).toLocaleString(dateFormatDayAndMonth);
};

const dateFormatMonthAndYear: LocaleOptions & DateTimeFormatOptions = {
  month: '2-digit',
  year: 'numeric',
};

export const formatDateWithMonthAndYear = (str: string) => {
  if (!str) return `‌‌ ‌‌ ${EMPTY_STR}‌‌ ‌‌ `;

  return DateTime.fromISO(str).toLocaleString(dateFormatMonthAndYear);
};

const dateFormatMonthStringAndYear: LocaleOptions & DateTimeFormatOptions = {
  month: 'long',
  year: 'numeric',
};

export const formatDateWithMonthStringAndYear = (str: string) => {
  if (!str) return `‌‌ ‌‌ ${EMPTY_STR}‌‌ ‌‌ `;

  return DateTime.fromISO(str).toLocaleString(dateFormatMonthStringAndYear);
};

export function formatContractType(type?: 'tenant' | 'building') {
  if (!type) return EMPTY_STR;

  switch (type) {
    case 'tenant':
      return 'Mieter';
    case 'building':
      return 'Gebäude';
    default:
      return type;
  }
}

export const RelationFormattersMap: { [key: string]: any } = {
  tariff: formatTariffPriceSheet,
  plant: formatPlant,
  meter: formatMeter,
  loadprofile: formatLoadProfiles,
  existingcustomer: formatSuggestedCustomer,
  balancingareaaccount: formatBalancingAreaAccount,
  supplier: formatSupplier,
  project: formatProject,
  balancingareagroupaccount: formatBalancingAreaAccount,
  meteringpointoperator: formatMeteringPointOperator,
  workspace: formatWorkspace,
  contract: formatContract,
  powersuppliers: formatPowerSupplier,
};

const inverseMap = (map: { [key: string]: string }) => {
  const inverse: { [key: string]: string } = {};

  for (const key in map) {
    inverse[map[key]] = key;
  }

  return inverse;
};

const documentTypesKeys = {
  welcome_letter: 'Willkommensschreiben',
  covering_letter: 'Anschreiben',
  poa_cancellation_letter: 'Vollmacht Kündigung',
  contract_confirmation_letter: 'Lieferbestätigung',
  accounting: 'Rechnung',
  invoice: 'Verbrauchsabrechnung',
  contract_draft_letter: 'Vertragsentwurf',
  pricesheet_change_letter: 'Preisänderungsschreiben',
  pricesheet_letter: 'Preisblatt',

  offer: 'Angebot',
  contract_document: 'Vertrag',
  supplier_contract_letter: 'Stromliefervertrag',
  budget_billing_request: 'Anfrage Abschlagsanpassung',
  budget_billing_confirmation: 'Bestätigung Abschlagsanpassung',
  paymentreminder: 'Zahlungserinnerung',
  overdue_notice: 'Mahnung',
  cancellation: 'Kündigungsschreiben',
  cancellation_confirmation: 'Kündigungsbestätigung',
  cancellation_powerofattorney: 'Vollmacht Kündigung',
  withdrawal_letter: 'Widerrufsformular',
  previous_provider_termination: 'Kündigungsschreiben beim alten Lieferanten',
  registration_ultimate_consumer: 'Anmeldung Letztverbraucher durch KAB',
  registration_malo: 'Anmeldung Malo durch KAB',
  other: 'Sonstiges',

  reversal_invoice: 'Stornorechnung',
  cancelled_invoice: 'Stornierte Rechnung',
} as const;

export const documentTypesKeyToI18n = {
  ...documentTypesKeys,
} as { [key: string]: string };

export type DocumentTypeKeys = keyof typeof documentTypesKeys;

export function translateDocumentType(type?: DocumentTypeKeys | string) {
  return (
    (type && documentTypesKeys[type as DocumentTypeKeys]) ??
    documentTypesKeys.other
  );
}

export const documentTypeGroups = [
  {
    name: 'Vertragsunterlagen',
    types: ['offer', 'contract_document', 'contract_draft_letter'],
  },
  {
    name: 'Abschlags- und Preisanpassung',
    types: [
      'budget_billing_request',
      'budget_billing_confirmation',
      'pricesheet_change_letter',
    ],
  },
  {
    name: 'Mahnwesen/Forderungsmanagement',
    types: ['paymentreminder', 'overdue_notice'],
  },
  {
    name: 'Rechnungen',
    types: ['invoice', 'accounting'],
  },
  {
    name: 'Stornorechnung',
    types: ['reversal_invoice'],
  },
  {
    name: 'Stornierte Rechnung',
    types: ['cancelled_invoice'],
  },
  {
    name: 'Kündigung',
    types: ['cancellation', 'cancellation_confirmation'],
  },
  {
    name: 'Wechselprozess',
    types: [
      'previous_provider_termination',
      'registration_ultimate_consumer',
      'registration_malo',
    ],
  },
  {
    name: 'Sonstiges',
    types: ['other'],
  },
];

export const documentTypesI18nToKey = inverseMap(documentTypesKeyToI18n);

export const removeFileExtension = (filename: string): string => {
  const extensionRegex = /\.[^.]+$/;

  return filename.replace(extensionRegex, '');
};
