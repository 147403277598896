import { _ae_env_ } from './env';

export const loadMSClarityScript = (cookiePresent: boolean) => {
  if (cookiePresent) {
    const script = document.createElement('script');

    script.id = 'ms-clarity';
    script.type = 'text/javascript';
    script.innerHTML = `
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(
      window,
      document,
      "clarity",
      "script",
      "${_ae_env_.REACT_APP_MS_CLARITY_TRACKING}"
    );
  `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }

  const sourceClarityScripts = document.querySelectorAll(
    `script[src*="${'clarity.ms'}"]`,
  );
  sourceClarityScripts.forEach((script) => {
    script.remove();
  });

  const script = document.getElementById('ms-clarity');

  if (script) {
    script.remove();
  }

  if (window.clarity) {
    window.clarity('stop');
  }
};

export const logErrorToClarity = (key?: string, value?: string | string[]) => {
  if (window.clarity) {
    window.clarity('set', 'error', `${key ?? ''}-${value ?? ''}`);
  }
};
